import React, { Component } from "react";
import { Link } from "react-router-dom";
import ModalImage from "react-modal-image";
import ReactToPrint from "react-to-print";
import { auth } from "../../Database/Firebase";
import home from "../../Styles/Home/home-icon-green.png";
import InvalidAuth from "../Authentication/InvalidAuth"
import CertificatedStudentsListStudentExams from "./CertificatedStudentsListStudentExams";

class PrintPage extends Component {

    constructor(props) {
        super(props)
        const taken_exams = this.props.student.takenExams;
        this.state = {
            takenExams: taken_exams ? taken_exams : []
        }
    }

    render() {
        const student = this.props.student;
        return (
            <div className="h-screen w-full break-words pb-8">
                {/* HEADER */}
                <div className="h-1/6 mb-4 flex font-sans text-2xl font-semibold tracking-wider text-left pt-8 text-lime-600 uppercase">
                    <h2 className="w-1/2 pt-4">{student.value.name} {student.value.surname}</h2>
                    <div className="w-1/2 bg-hmc-aviation bg-contain bg-no-repeat bg-right" />
                </div>

                <div className="h-5/6 w-full mt-4 font-sans text-lg border-lime-500 border-2">

                    {/* PERSONAL INFO */}
                    <div className="h-full w-full my-4 flex font-sans">
                        <form className='h-full w-full px-10 grid grid-rows-5 grid-cols-2 gap-x-4 gap-y-0'>
                            <label className="block">
                                <span className="text-lime-600 text-xl">Name</span>
                                <p className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 lowercase uppercase">
                                    {student.value.name}
                                </p>
                            </label>
                            <label className="block">
                                <span className="text-lime-600 text-xl">Surname</span>
                                <p className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 uppercase">
                                    {student.value.surname}
                                </p>
                            </label>
                            <label className="block">
                                <span className="text-lime-600 text-xl">Email</span>
                                <p className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0">
                                    {student.value.email}
                                </p>
                            </label>
                            <label className="block">
                                <span className="text-lime-600 text-xl">Phone</span>
                                <p className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 uppercase">
                                    {student.value.phone}
                                </p>
                            </label>
                            <label className="block">
                                <span className="text-lime-600 text-xl">Birthdate</span>
                                <p className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 uppercase">
                                    {student.value.birthdate}
                                </p>
                            </label>
                            <label className="block">
                                <span className="text-lime-600 text-xl">Birthplace</span>
                                <p className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 uppercase">
                                    {student.value.birthplace}
                                </p>
                            </label>
                            <label className="block h-30 w-30">
                                <span className="text-lime-600 text-xl">Passport Image</span>
                                <ModalImage
                                    className="h-24"
                                    small={student.value.passport}
                                    large={student.value.passport}
                                    alt="Passport Image" />
                            </label>
                            <div className={this.props.noteVisible ? "block visible" : "block invisible"}>
                                <span className="text-lime-600 text-xl">Notes</span>
                                <textarea
                                    className="mt-1 block w-full h-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 uppercase"
                                    disabled={true}>
                                    {student.value.notes}
                                </textarea>
                            </div>
                        </form>
                    </div>

                    {/* EXAM INFO */}
                    <div className="h-auto w-full my-4 px-4 pt-4 inline-flex grid grid-cols-3 gap-x-8 gap-y-0 font-sans text-lg border-lime-500 border-2 uppercase">
                        <div className="w-full">
                            <p className="w-full text-l text-center font-semibold underline text-lime-600">Module Easa & Sghm</p>
                            {this.state.takenExams
                                .map((exam, index) => {
                                    if (exam.category === 'module_easa_sghm') {
                                        return (
                                            <CertificatedStudentsListStudentExams key={exam.key} exam={exam} index={index} />
                                        )
                                    } else {
                                        return null;
                                    }
                                })}
                        </div>
                        <div className="w-full">
                            <p className="w-full text-l text-center font-semibold underline text-lime-600">Module Easa</p>
                            {this.state.takenExams
                                .map((exam, index) => {
                                    if (exam.category === 'module_easa') {
                                        return (
                                            <CertificatedStudentsListStudentExams key={exam.key} exam={exam} index={index} />
                                        )
                                    } else {
                                        return null;
                                    }
                                })}
                        </div>
                        <div>
                            <p className="w-full text-l text-center font-semibold underline text-lime-600">Training</p>
                            {this.state.takenExams
                                .map((exam, index) => {
                                    if (exam.category === 'training') {
                                        return (
                                            <CertificatedStudentsListStudentExams key={exam.key} exam={exam} index={index} />
                                        )
                                    } else {
                                        return null;
                                    }
                                })}
                        </div>
                    </div>
                </div>

            </div >
        )
    }

}

class CertificatedStudentsListStudent extends React.Component {

    constructor() {
        super()
        this.state = {
            noteVisible: false,
            isUserSignedIn: false,
            buttonVisible: false
        }
    }

    onShowHideClick(event) {
        event.preventDefault();
        this.setState({ noteVisible: !this.state.noteVisible })
    }

    componentDidMount() {
        auth.onAuthStateChanged((user) => {
            if (user) {
                if (
                    user.email === 'gulin.gezelge@gmail.com' ||
                    user.email === 'hmc@hmcaviationmaintenance.com' ||
                    user.email === 'eliftultay@gmail.com') {
                    this.setState({ isUserSignedIn: true, noteVisible: true, buttonVisible: true });;
                } else if (
                    user.email === 'suleelik@gmail.com' ||
                    user.email === 'hmcaviationsupervisor@gmail.com') {
                    this.setState({ isUserSignedIn: true, noteVisible: false, buttonVisible: false });;
                } else {
                    this.setState({ isUserSignedIn: false, noteVisible: false, buttonVisible: false });
                }
            } else {
                this.setState({ isUserSignedIn: false });
            }
        });
    }

    render() {
        if (!this.state.isUserSignedIn) {
            return (
                <InvalidAuth></InvalidAuth>
            )
        }
        return (
            <div className="w-full px-20">
                <div className="h-16 pt-2 border-b-2 border-lime-300 flex">
                    <div className="ml-auto flex">
                        <button
                            className={this.state.buttonVisible ?
                                this.state.noteVisible ?
                                    'rounded-full w-10 h-10 focus:outline-none bg-invisible bg-contain bg-no-repeat'
                                    : 'rounded-full w-10 h-10 focus:outline-none bg-visible bg-contain bg-no-repeat'
                                : 'rounded-full w-10 h-10 focus:outline-none invisible'}
                            onClick={(event) => { this.onShowHideClick(event) }}></button>
                        <ReactToPrint className=""
                            trigger={() =>
                                <button className="rounded-full ml-10 w-10 h-10 bg-print bg-contain bg-no-repeat"
                                ></button>}
                            content={() => this.componentRef}
                        />
                        <Link to="/home" className="ml-10">
                            <img className="rounded-full w-10 h-10 block" src={home} alt="home" />
                        </Link>
                    </div>
                </div>
                <PrintPage
                    ref={(el) => (this.componentRef = el)}
                    student={this.props.location.state.student}
                    noteVisible={this.state.noteVisible} />
            </div>
        );
    }
}

export default CertificatedStudentsListStudent