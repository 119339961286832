import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/storage";

const firebaseApp = firebase.initializeApp({
  // Production
   apiKey: "AIzaSyD7JPT7ZH-6OwU5HWxz3HSSXyVSJOGZJ3k",
  authDomain: "hmcwebapp.firebaseapp.com",
  projectId: "hmcwebapp",
  storageBucket: "hmcwebapp.appspot.com",
  messagingSenderId: "410197329292",
  appId: "1:410197329292:web:b8eee4f1213d488c8dc2a9",
  measurementId: "G-DPE5G962SP"
  // Test
/*    apiKey: "AIzaSyAjKFEN-5Bi5u_DoogBNKGs693_CIgQA9Q",
    authDomain: "vetclinic-b0d34.firebaseapp.com",
    databaseURL: "https://vetclinic-b0d34-default-rtdb.firebaseio.com",
    projectId: "vetclinic-b0d34",
    storageBucket: "vetclinic-b0d34.appspot.com",
    messagingSenderId: "643221921803",
    appId: "1:643221921803:web:10eed564fb1cc4da60670e" */
});


const storage = firebaseApp.storage();
const auth = firebaseApp.auth();
const database = firebaseApp.database();

export default firebaseApp
export { auth }
export { database };
export { storage };