import React, { Component } from "react";
import { Link } from "react-router-dom";
import { database, auth } from "../../Database/Firebase";
import home from "../../Styles/Home/home-icon-green.png";
import CertificatedStudentsList from "./CertificatedStudentsList";
import InvalidAuth from "../Authentication/InvalidAuth"

class CertificatedStudents extends Component {

    constructor() {
        super()
        this.state = {
            certificatedStudents: [],
            isUserSignedIn: false
        }
    }

    async fetchStudents() {
        var studentListRef = database.ref('/certificated_students');
        await studentListRef.on('value', (snapshot) => {
            var studentList = [];
            snapshot.forEach((childSnapshot) => {
                let taken_exams = []
                childSnapshot.child('taken_exams').forEach((taken_exam) => {
                    taken_exams.push(taken_exam.val());
                });
                studentList.push({
                    'key': childSnapshot.key,
                    'value': childSnapshot.val(),
                    'takenExams': taken_exams
                });
            });
            studentList.sort((a, b) => (a.value.name + " " + a.value.surname).localeCompare(b.value.name + " " + b.value.surname) === 1 ? 1 : -1)
            this.setState({ certificatedStudents: studentList });
        });
    }

    componentDidMount() {
        this.fetchStudents();
        auth.onAuthStateChanged((user) => {
            if (user) {
                this.setState({ isUserSignedIn: true });
            } else {
                this.setState({ isUserSignedIn: false });
            }
        });
    }

    render() {
        if (!this.state.isUserSignedIn) {
            return (
                <InvalidAuth></InvalidAuth>
            )
        }
        return (
            <div className="break-words px-20 pb-8 mb-4">
                <div className="h-1/6 flex font-sans text-3xl font-semibold tracking-wider text-left py-8 text-lime-600 border-b-2 border-lime-200">
                    <h2 className="w-1/2 pt-4">Certificated Students</h2>
                    <Link className="w-16 ml-auto" to="/home">
                        <img className="rounded-full bg-contain bg-center bg-no-repeat" src={home} alt="home" />
                    </Link>
                </div>
                <div className="mt-6 grid grid-cols-3 gap-x-8 gap-y-0 capitalize">
                    {this.state.certificatedStudents.map((student, index) =>
                        <CertificatedStudentsList key={student.key} student={student}
                            index={index} />)}
                </div>
            </div>
        )
    }
}

export default CertificatedStudents