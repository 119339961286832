import React, { Component } from "react";
import { database } from "../../Database/Firebase";

class ResultsListAddResultPassed extends Component {
    async onCancel(event) {
        event.preventDefault();
        let examKey = this.props.examKey;
        let studentKey = this.props.student.key;
        await database.ref('students/' + studentKey + '/taken_exams/' + examKey).update({
            status: 'none'
        }, (error) => {
            if (error) {
                console.log(error);
            }
        });
        await database.ref('exam_dates/' + examKey + '/exam_takers/' + studentKey).update({
            status: 'none'
        }, (error) => {
            if (error) {
                console.log(error);
            }
        });
    }

    render() {
        return (
            <div>
                {!this.props.student.value.certificated ?
                    <div className="w-full inline-flex grid grid-cols-6 gap-x-4 px-4 py-2 shadow-md capitalize">
                        <label className="col-span-5">
                            {this.props.student.value.name} {this.props.student.value.surname}
                        </label>
                        <button className="col-span-1 rounded-full hover:bg-red-600 bg-red-500 focus:outline-none bg-contain bg-center bg-no-repeat bg-cancel"
                            onClick={(event) => { this.onCancel(event) }}
                        >
                        </button>
                    </div>
                    :
                    <div className="w-full inline-flex px-4 py-2 mb-2 shadow-md bg-gray-200 capitalize">
                        <label>
                            {this.props.student.value.name} {this.props.student.value.surname}
                        </label>
                    </div>
                }
            </div>
        )
    }
}

export default ResultsListAddResultPassed