import React, { Component } from "react";
import { Link } from "react-router-dom";
import { database, auth } from "../../Database/Firebase";
import home from "../../Styles/Home/home-icon-purple.png";
import StudentsList from './StudentsList';
import InvalidAuth from "../Authentication/InvalidAuth"

class Students extends Component {

    constructor() {
        super()
        this.state = {
            students: [],
            isUserSignedIn: false,
            searchString: ''
        }
    }

    async fetchStudents() {
        var studentListRef = database.ref('/students').orderByChild('name');
        await studentListRef.on('value', (snapshot) => {
            var studentList = [];
            snapshot.forEach((childSnapshot) => {
                studentList.push({ 'key': childSnapshot.key, 'value': childSnapshot.val() });
            })
            this.setState({ students: studentList });
        });
    }

    componentDidMount() {
        this.fetchStudents();
        auth.onAuthStateChanged((user) => {
            if (user) {
                this.setState({ isUserSignedIn: true });
            } else {
                this.setState({ isUserSignedIn: false });
            }
        });
    }

    handleChange = event => {
        const { value } = event.target;
        this.setState({ searchString: value });
    };

    render() {
        if (!this.state.isUserSignedIn) {
            return (
                <InvalidAuth></InvalidAuth>
            )
        }
        return (
            <div className="h-screen break-words px-20 pb-8">
                <div className="h-1/6 flex font-sans text-3xl font-semibold tracking-wider text-left pt-8 text-lila border-b-2 border-purple-200">
                    <h2 className="pt-4">Students</h2>
                    <div className="w-1/4 h-1/2 mt-3 ml-auto border-b-2 border-l-0 border-r-0 border-t-0 flex text-gray-600" >
                        <input className="border-none w-5/6 focus:ring-0"
                            type="text" value={this.state.searchString} onChange={this.handleChange} />
                        <p className="border-none w-6 h-6 ml-auto bg-search bg-contain bg-no-repeat"></p>
                    </div>
                    <Link className="w-16 ml-auto" to="/home">
                        <img className="rounded-full" src={home} alt="purple" />
                    </Link>
                </div>
                {
                <div className="grid grid-cols-6 gap-x-8 gap-y-0 uppercase">
                {this.state.students.map((student, index) => {
                    if((student.value.name + student.value.surname).includes(this.state.searchString.toLowerCase())) {
                        return <StudentsList key={student.key} student={student} index={index} filterPage={false} />
                    } else {
                        return null;
                    }
                })}
                </div>
                }
            </div>
        )
    }
}

export default Students