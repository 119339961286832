import React, { Component } from "react";
import { Link } from "react-router-dom";
import { database, auth } from "../../Database/Firebase";
import home from "../../Styles/Home/home-icon-yellow.png";
import ExamsList from './ExamsList';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'
import InvalidAuth from "../Authentication/InvalidAuth"
import FilterResults from 'react-filter-search';

class Exams extends Component {
    constructor() {
        super()
        this.state = {
            exams: [],
            easa: [],
            shgm: [],
            training: [],
            filterExams: [],
            isUserSignedIn: false,
            allButton: false,
            easaButton: false,
            shgmButton: false,
            trainingButton: false,
            filtered: false,
            searchString: ''
        }
    }

    showConfirmPopup = function (event, category, type, date, onRemoveExam, keyVal, examTakers) {
        event.preventDefault();
        confirmAlert({
            title: 'DELETE EXAM',
            message: 'Are you sure to delete:',
            customUI: ({ title, message, onClose }) =>
                <div className="w-80 px-10 py-8 bg-white font-family:Arial text-gray-600 text-center border rounded border-gray-600">
                    <p className="w-full mb-1">{title}</p>
                    <p className="w-full mb-3">
                        {message}<br />
                        {category}<br />
                        {type}<br />
                        {date}<br />
                    </p>
                    <div className="grid grid-cols-2 gap-x-2">
                        <button className="w-full rounded text-white bg-lime-400 hover:bg-lime-600 focus:outline-none"
                            onClick={(event) => { onRemoveExam(keyVal, this.showResultPopup, examTakers); onClose(); }}>
                            YES
                        </button>
                        <button className="w-full rounded text-white bg-red-400 hover:bg-red-600 focus:outline-none"
                            onClick={(event) => { onClose() }}>
                            NO
                        </button>
                    </div>

                </div>
        });
    };

    showResultPopup = function (popupMessage) {
        confirmAlert({
            title: 'DELETE EXAM',
            message: popupMessage,
            customUI: ({ title, message, onClose }) =>
                <div className="w-80 px-10 py-8 bg-white font-family:Arial text-gray-600 text-center border rounded border-gray-600">
                    <p className="w-full mb-1">{title}</p>
                    <p className="w-full mb-3">{message}</p>
                    <div className="">
                        <button className="w-full rounded text-white bg-lime-400 hover:bg-lime-600 focus:outline-none"
                            onClick={(event) => onClose()}>
                            OK
                        </button>
                    </div>

                </div>
        });
    };

    async fetchExams() {
        const examRef = database.ref('exam_dates/').orderByChild('date');
        await examRef.on("value", (snapshot) => {
            let exam = [];
            let easa = [];
            let shgm = [];
            let training = [];

            snapshot.forEach((childSnapshot) => {
                let exam_takers = [];
                childSnapshot.child('exam_takers').forEach((childSnapshot) => {
                    exam_takers.push({ 'key': childSnapshot.key, 'value': childSnapshot.val() })
                });
                exam.push({
                    'key': childSnapshot.key,
                    'value': childSnapshot.val(),
                    'exam_takers': exam_takers
                });
                if (childSnapshot.val().category === "module_easa") {
                    easa.push({
                        'key': childSnapshot.key,
                        'value': childSnapshot.val(),
                        'exam_takers': exam_takers
                    });
                } else if (childSnapshot.val().category === "module_easa_sghm") {
                    shgm.push({
                        'key': childSnapshot.key,
                        'value': childSnapshot.val(),
                        'exam_takers': exam_takers
                    });
                } else {
                    training.push({
                        'key': childSnapshot.key,
                        'value': childSnapshot.val(),
                        'exam_takers': exam_takers
                    });
                }
            })
            this.setState({ exams: exam })
            this.setState({ easa: easa })
            this.setState({ shgm: shgm })
            this.setState({ training: training })
        })
    }

    async handleEasaFilter() {
        await this.setState({ filterExams: [] })
        await this.setState({ filtered: true })
        if (this.state.easaButton === true) {
            this.setState({ allButton: false });
            this.setState({ shgmButton: false });
            this.setState({ trainingButton: false });
            this.setState({ filterExams: this.state.easa })
        } else {
            this.setState({ shgmButton: false })
            this.setState({ trainingButton: false })
            this.setState({ filtered: false })
        }
    }

    async handleShgmFilter() {
        await this.setState({ filterExams: [] })
        await this.setState({ filtered: true })
        if (this.state.shgmButton === true) {
            this.setState({ allButton: false });
            this.setState({ easaButton: false });
            this.setState({ trainingButton: false });
            this.setState({ filterExams: this.state.shgm })
        } else {
            this.setState({ easaButton: false });
            this.setState({ trainingButton: false });
            this.setState({ shgmButton: false })
            this.setState({ filtered: false })
        }
    }

    async handleTrainingFilter() {
        await this.setState({ filterExams: [] })
        await this.setState({ filtered: true })
        if (this.state.trainingButton === true) {
            this.setState({ allButton: false });
            this.setState({ easaButton: false });
            this.setState({ shgmButton: false });
            this.setState({ filterExams: this.state.training })
        } else {
            this.setState({ easaButton: false });
            this.setState({ shgmButton: false })
            this.setState({ filtered: false })
        }
    }
    async handleAllFilter() {
        await this.setState({ filterExams: [] })
        if (this.state.allButton === true) {
            this.setState({ filtered: false })
            this.setState({ easaButton: false });
            this.setState({ shgmButton: false });
            this.setState({ trainingButton: false });
            this.setState({ filterExams: this.state.exams });
        }
    }
    handleChange = event => {
        const { value } = event.target;
        this.setState({ searchString: value });
    };

    componentDidMount() {
        this.fetchExams();
        auth.onAuthStateChanged((user) => {
            if (user) {
                this.setState({ isUserSignedIn: true });
            } else {
                this.setState({ isUserSignedIn: false });
            }
        });
    }

    render() {
        if (!this.state.isUserSignedIn) {
            return (
                <InvalidAuth></InvalidAuth>
            )
        }
        return (
            <div className="h-screen break-words px-20 pb-8">
                <div className="h-1/6 flex font-sans text-3xl font-semibold tracking-wider text-left pt-8 text-yellow border-b-2 border-yellow-200">
                    <h2 className="w-1/5 pt-4 text-yellow-400">List Exams</h2>
                    <button className={!this.state.allButton ?
                        "w-auto h-1/2 mt-3 mx-4 px-2 border hover:bg-gray-200 rounded-2xl text-base focus:outline-none"
                        :
                        "w-auto h-1/2 mt-3 mx-4 px-2 border bg-yellow-300 hover:bg-gray-200 rounded-2xl text-base focus:outline-none"}
                        onClick={(event) => {
                            event.preventDefault();
                            this.setState({ allButton: true });
                            this.handleAllFilter();
                        }}
                    >ALL</button>
                    <button
                        className={!this.state.easaButton ?
                            "w-auto h-1/2 mt-3 mx-4 px-2 border hover:bg-gray-200 rounded-2xl text-base focus:outline-none"
                            :
                            "w-auto h-1/2 mt-3 mx-4 px-2 border bg-yellow-300 hover:bg-gray-200 rounded-2xl text-base focus:outline-none"}
                        onClick={(event) => {
                            event.preventDefault();
                            this.setState({ easaButton: !this.state.easaButton });
                            this.handleEasaFilter();
                        }}
                    >EASA</button>
                    <button className={!this.state.shgmButton ?
                        "w-auto h-1/2 mt-3 mx-4 px-2 border hover:bg-gray-200 rounded-2xl text-base focus:outline-none"
                        :
                        "w-auto h-1/2 mt-3 mx-4 px-2 border bg-yellow-300 hover:bg-gray-200 rounded-2xl text-base focus:outline-none"}
                        onClick={(event) => {
                            event.preventDefault();
                            this.setState({ shgmButton: !this.state.shgmButton });
                            this.handleShgmFilter();
                        }}
                    >EASA & SHGM</button>
                    <button className={!this.state.trainingButton ?
                        "w-auto h-1/2 mt-3 mx-4 px-2 border hover:bg-gray-200 rounded-2xl text-base focus:outline-none"
                        :
                        "w-auto h-1/2 mt-3 mx-4 px-2 border bg-yellow-300 hover:bg-gray-200 rounded-2xl text-base focus:outline-none"}
                        onClick={(event) => {
                            event.preventDefault();
                            this.setState({ trainingButton: !this.state.trainingButton });
                            this.handleTrainingFilter();
                        }}
                    >TYPE TRAINING</button>
                    <div className="w-1/4 h-1/2 mt-3 mx-4 ml-auto border-b-2 border-l-0 border-r-0 border-t-0 flex text-gray-600" >
                        <input className="border-none w-5/6 focus:ring-0"
                            type="text" value={this.state.searchString} onChange={this.handleChange} />
                        <p className="border-none w-6 h-6 ml-auto bg-search bg-contain bg-no-repeat"></p>
                    </div>
                    <Link className="w-16 ml-auto" to="/home">
                        <img className="rounded-full" src={home} alt="yellow" />
                    </Link>
                </div>
                {
                    this.state.filtered ?
                        <FilterResults
                            value={this.state.searchString}
                            data={this.state.filterExams}
                            renderResults={results => (
                                <div className="grid grid-cols-5 gap-x-4 gap-y-0">
                                    {results.sort(function (a, b) {
                                        if (a.value.date > b.value.date) { return -1; }
                                        if (a.value.date < b.value.date) { return 1; }
                                        return 0;
                                    })
                                        .map((exam, index) =>
                                            <ExamsList
                                                key={exam.key}
                                                exam={exam}
                                                index={index}
                                                showConfirmPopup={this.showConfirmPopup}
                                                showResultPopup={this.showResultPopup}
                                            />)}
                                </div>
                            )}
                        />
                        :
                        <FilterResults
                            value={this.state.searchString}
                            data={this.state.exams}
                            renderResults={results => (
                                <div className="grid grid-cols-5 gap-x-4 gap-y-0">
                                    {results.sort(function (a, b) {
                                        if (a.value.date > b.value.date) { return -1; }
                                        if (a.value.date < b.value.date) { return 1; }
                                        return 0;
                                    }).map((exam, index) =>
                                        <ExamsList
                                            key={exam.key}
                                            exam={exam}
                                            index={index}
                                            showConfirmPopup={this.showConfirmPopup}
                                            showResultPopup={this.showResultPopup}
                                        />)}
                                </div>
                            )}
                        />
                }
            </div>
        )
    }

}

export default Exams