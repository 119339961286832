import React, { Component } from 'react';
import { auth } from "../../Database/Firebase";

class Login extends Component {
  constructor() {
    super()
    this.state = {
      email: '',
      password: '',
      error: ''
    }
  }

  login(event, email, password) {
    event.preventDefault();
    auth.signInWithEmailAndPassword(email, password)
      .then((userCredential) => {
        // Signed in
        var user = userCredential.user;
        if (user !== null) {
          this.props.history.push('/home');
        }
      })
      .catch((error) => {
        console.log(error.code + " " + error.message)
      });

  };

  onChangeHandler = (event) => {
    const { name, value } = event.currentTarget;
    if (name === 'userEmail') {
      this.setState({ email: value })
    }
    else if (name === 'userPassword') {
      this.setState({ password: value })
    }
  };

  handleKeyPress(event) {
    if (event.key === 'Enter') {
      this.login(event, this.state.email, this.state.password);
    }
  }

  render() {
    return (
      <div className="mt-20" onKeyDown={(event) => { this.handleKeyPress(event, this.state.email, this.state.password) }}>
        <h1 className="text-5xl pb-2 mb-4 text-center font-bold text-blue-500 text-shadow">HMC Aviation Test Center</h1>
        <div className="border border-coolgray-300 mx-auto w-1/3 rounded-2xl py-6 px-4">
          {this.state.error !== null && <div className="py-1 w-full text-red-500 text-center mb-3 bg-transparent">{this.state.error}</div>}
          <form className='h-full w-full px-10'>
            <label htmlFor="userEmail" className="font-sans text-gray-700 text-xl">
              Email:
            </label>
            <input
              type="email"
              className="mt-1 mb-6 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0"
              name="userEmail"
              placeholder="email"
              id="userEmail"
              onChange={(event) => this.onChangeHandler(event)}
            />
            <label htmlFor="userPassword" className="font-sans text-gray-700 text-xl">
              Password:
            </label>
            <input
              type="password"
              className="mt-1 mb-10 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0"
              name="userPassword"
              placeholder="password"
              id="userPassword"
              onChange={(event) => this.onChangeHandler(event)}
            />
          </form>
          <button className="bg-lime-500 hover:bg-lime-600 w-3/4 mx-auto block text-white rounded-2xl text-2xl focus:outline-none" onClick={(event) => { this.login(event, this.state.email, this.state.password) }}>
            Sign in
          </button>
        </div>
      </div>
    );
  }
}

export default Login