import React, { Component } from 'react';
import { Route, Switch } from "react-router-dom";
import Home from "./Home";
import Login from "./Authentication/Login";
import CreateStudent from "./CreateStudent/CreateStudent";
import CreateExamDate from './CreateExamDate/CreateExamDate';
import Results from './Results/Results';
import ResultsListAddResult from './Results/ResultsListAddResult';
import Students from './Students/Students';
import StudentsListEditStudent from './Students/StudentsListEditStudent';
import ExamsListAssign from './Exams/ExamsListAssign';
import ExamsListEditExam from './Exams/ExamsListEditExam';
import Exams from './Exams/Exams';
import CertificatedStudents from './Certificate/CertificatedStudents';
import CertificatedStudentsListStudent from './Certificate/CertificatedStudentsListStudent';
import FilterPage from './Filter/FilterPage';

class Application extends Component {

    render() {
        return (
            <div>
                <Switch>
                    <Route exact path="/home" component={Home} />
                    <Route exact path="/" component={Login} />
                    <Route exact path="/create_student" component={CreateStudent} />
                    <Route exact path="/create_exam_date" component={CreateExamDate} />

                    <Route exact path="/students" component={Students} />
                    <Route exact path="/students/profile/:id" component={StudentsListEditStudent} />

                    <Route exact path="/exams" component={Exams} />
                    <Route exact path="/exams/assign/:id" component={ExamsListAssign} />
                    <Route exact path="/exams/edit/:id" component={ExamsListEditExam} />

                    <Route exact path="/results" component={Results} />
                    <Route exact path="/results/add/:id" component={ResultsListAddResult} />

                    <Route exact path="/certificated" component={CertificatedStudents} />
                    <Route exact path="/certificated/profile/:id" component={CertificatedStudentsListStudent} />

                    <Route exact path="/filter" component={FilterPage} />
                </Switch>
            </div>
        );
    }
}

export default Application