import React, { Component } from "react";
import { Link } from "react-router-dom";
import { database, auth } from "../../Database/Firebase";
import home from "../../Styles/Home/home-icon-blue.png";
import StudentsList from '../Students/StudentsList';
import InvalidAuth from "../Authentication/InvalidAuth"

class FilterPage extends Component {

    constructor(props) {
        super(props);
        if (this.props.location.state !== null) {
            var filterRouted = this.props.location.state.filter;

            var categoryRouted = this.props.location.state.category;

            var examIdRouted = this.props.location.state.examId;

            var examTypeRouted = this.props.location.state.examType;

        }
        filterRouted = filterRouted !== null ? filterRouted : 'Filter students by exams to take';
        categoryRouted = categoryRouted !== null ? categoryRouted : 'module_easa';
        examIdRouted = examIdRouted !== null ? examIdRouted : 'DWEYGZH2K4';
        examTypeRouted = examTypeRouted !== null ? examTypeRouted : 'M 01';

        this.state = {
            isUserSignedIn: false,
            students: [],
            moduleEasa: [],
            moduleEasaShgm: [],
            trainings: [],
            categories: [],
            cities: [],
            filter: filterRouted,
            category: categoryRouted,
            examType: examTypeRouted,
            examId: examIdRouted,
            selectedExamTypeIndex: 0,
            filterResult: [],
            isEasaChecked: true,
            isEasaShgmChecked: false,
            isTrainingChecked: false
        }
        this.fetchStudents();
        this.onLoadExamInfo();
        this.onLoadCity();
    }

    componentDidMount() {
        auth.onAuthStateChanged((user) => {
            if (user) {
                this.setState({ isUserSignedIn: true });
            } else {
                this.setState({ isUserSignedIn: false });
            }
        });
    }

    async fetchStudents() {
        let studentListRef = database.ref('/students').orderByChild('name');
        await studentListRef.once('value').then((snapshot) => {
            let studentList = [];
            snapshot.forEach((childSnapshot) => {
                let studentKey = childSnapshot.key;
                let student = childSnapshot.val();
                studentList.push({ 'key': studentKey, 'value': student });
            })
             this.state.students = studentList;
            this.updateFilter();
        }).catch((e) => {
            console.log(e);
        });
    }

    async onLoadExamInfo() {
        await database.ref('exams/').once('value').then((snapshot) => {
            let i = 0;
            let tempCategories = [];
            let tempModuleEasa = [];
            let tempModuleEasaShgm = [];
            let tempTrainings = [];

            snapshot.forEach((categorySnapshot) => {
                tempCategories.push(categorySnapshot.key.replace(/_/g, " "))
                categorySnapshot.forEach((examTypeSnaphot) => {
                    if (i === 0) {
                        tempModuleEasa.push({ examId: examTypeSnaphot.key, examName: examTypeSnaphot.val(), category: 'module_easa' });
                    }
                    else if (i === 1) {
                        tempModuleEasaShgm.push({ examId: examTypeSnaphot.key, examName: examTypeSnaphot.val(), category: 'module_easa_shgm' });
                    }
                    else if (i === 2) {
                        tempTrainings.push({ examId: examTypeSnaphot.key, examName: examTypeSnaphot.val(), category: 'training' });
                    }
                });
                i++;
            });
            tempModuleEasa.sort((a, b) => a.examName.localeCompare(b.examName) === 1 ? 1 : -1);
            tempModuleEasaShgm.sort((a, b) => a.examName.localeCompare(b.examName) === 1 ? 1 : -1);
            tempTrainings.sort((a, b) => a.examName.localeCompare(b.examName) === 1 ? 1 : -1);
            this.setState({ categories: tempCategories });
            this.setState({ moduleEasa: tempModuleEasa });
            this.setState({ moduleEasaShgm: tempModuleEasaShgm });
            this.setState({ trainings: tempTrainings });
        }).catch((e) => {
            console.log(e);
        });
    };

    async onLoadCity() {
        await database.ref('cities/exam_cities/').once('value').then((snapshot) => {
            let temp = [];
            snapshot.forEach((citySnapshot) => {
                temp.push(citySnapshot.val())
            });
            this.setState({ cities: temp })
        }).catch((e) => {
            console.log(e)
        })
    }

    updateFilter() {
        let tempStudentList = [];
        this.state.students.forEach((singleStudent) => {
            let studentKey = singleStudent.key;
            let student = singleStudent.value;
            if (student.exams_to_take !== null && student.exams_to_take[this.state.category] !== null
                && student.exams_to_take[this.state.category][this.state.examId] !== null) {
                let takenBefore = false;
                let passedBefore = false;
                for (let takenExamKey in student.taken_exams) {
                    let takenExam = student.taken_exams[takenExamKey];
                    if (takenExam.category === this.state.category && takenExam.type === this.state.examType) {
                        takenBefore = true;
                        passedBefore = takenExam.status === 'passed';
                        break;
                    }
                };
                if (takenBefore === true) {
                    if (passedBefore !== true)
                        tempStudentList.push({ 'key': studentKey, 'value': student, 'takenBefore': true, 'passedBefore': false });
                } else {
                    tempStudentList.push({ 'key': studentKey, 'value': student, 'takenBefore': false });
                }
            }
        });
        this.setState({ filterResult: tempStudentList });
    }

    onSelectFilterHandler(event) {
        event.preventDefault();
        const element = event.target;
        let text = element.options[element.selectedIndex].text;
        this.setState({ filter: text });
    }

    onSelectExamCategoryHandler(event) {
        event.preventDefault();
        const selectElement = event.target;
        let text = selectElement.options[selectElement.selectedIndex].text;
        this.state.category = text;
        this.setState({ category: text });
        if (text === 'module_easa') {
            this.state.examId = this.state.moduleEasa[0].examId;
            this.state.examType = this.state.moduleEasa[0].examName;
        } else if (text === 'module_easa_shgm') {
            this.state.examId = this.state.moduleEasaShgm[0].examId;
            this.state.examType = this.state.moduleEasaShgm[0].examName;
        } else if (text === 'training') {
            this.state.examId = this.state.trainings[0].examId;
            this.state.examType = this.state.trainings[0].examName;
        }
        this.updateFilter();
    }

    onSelectExamTypeHandler(event) {
        event.preventDefault();
        const selectElement = event.target;
        let text = selectElement.options[selectElement.selectedIndex].text;
        this.state.selectedExamTypeIndex = selectElement.selectedIndex;
        this.state.examType = text;
        if (this.state.category === 'module_easa') {
            for (let i = 0; i < this.state.moduleEasa.length; i++) {
                if (this.state.moduleEasa[i].examName === text) {
                    //this.setState({ examId: this.state.moduleEasa[i].examId })
                    this.state.examId = this.state.moduleEasa[i].examId;
                    break;
                }
            }
        } else if (this.state.category === 'module_easa_shgm') {
            for (let i = 0; i < this.state.moduleEasaShgm.length; i++) {
                if (this.state.moduleEasaShgm[i].examName === text) {
                    //this.setState({ examId: this.state.moduleEasaShgm[i].examId })
                    this.state.examId = this.state.moduleEasaShgm[i].examId;
                    break;
                }
            }
        } else if (this.state.category === 'training') {
            for (let i = 0; i < this.state.trainings.length; i++) {
                if (this.state.trainings[i].examName === text) {
                    //this.setState({ examId: this.state.trainings[i].examId })
                    this.state.examId = this.state.trainings[i].examId;
                    break;
                }
            }
        }
        this.updateFilter();
    }

    render() {
        if (!this.state.isUserSignedIn) {
            return (
                <InvalidAuth></InvalidAuth>
            )
        }
        return (
            <div className="h-screen px-20 pb-8">
                {/* Header */}
                <div className=" flex font-sans text-3xl font-semibold text-left pt-8 text-blue-400">
                    <h2 className="pt-4">Filter Page</h2>
                    <Link className="w-16 ml-auto" to="/home">
                        <img className="rounded-full" src={home} alt="blue" />
                    </Link>
                </div>

                {/* Elements */}
                <div className="w-full flex font-sans text-2xl font-semibold text-left text-blue-400 border-2 border-blue-100 p-4 mt-4">
                    <h4 className="">Filter Options:</h4>
                    <select
                        name="filterSelect"
                        id="filterSelect"
                        value={this.state.filter}
                        onChange={(event) => this.onSelectFilterHandler(event)}
                        className="rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0">
                        <option>Filter students by exams to take</option>
                    </select>
                </div>
                <div className="w-full flex font-sans text-2xl font-semibold text-left text-blue-400 border-2 border-blue-100 p-4 mt-4">
                    <h4 className="">Category:</h4>
                    <select
                        name="categorySelect"
                        id="categorySelect"
                        value={this.state.category}
                        onChange={(event) => this.onSelectExamCategoryHandler(event)}
                        className="ml-4 rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0">
                        <option>module_easa</option>
                        <option>module_easa_shgm</option>
                        <option>training</option>
                    </select>
                    <h4 className="ml-4">Exam Type:</h4>
                    <select
                        name="examTypeSelect"
                        id="examTypeSelect"
                        value={this.state.examType}
                        onChange={(event) => this.onSelectExamTypeHandler(event)}
                        className="ml-4 rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0">
                        {
                            this.state.moduleEasa.sort()
                                .map((examType, index) => {
                                    if (this.state.category === 'module_easa') {
                                        return (
                                            <option key={index}> {examType.examName} </option>
                                        )
                                    } else {
                                        return null;
                                    }
                                })
                        }
                        {
                            this.state.moduleEasaShgm.sort()
                                .map((examType, index) => {
                                    if (this.state.category === 'module_easa_shgm') {
                                        return (
                                            <option key={index}> {examType.examName} </option>
                                        )
                                    } else {
                                        return null;
                                    }
                                })
                        }
                        {
                            this.state.trainings.sort()
                                .map((examType, index) => {
                                    if (this.state.category === 'training') {
                                        return (
                                            <option key={index}> {examType.examName} </option>
                                        )
                                    } else {
                                        return null;
                                    }
                                })
                        }
                    </select>
                </div>
                <h2 className="w-full font-sans text-2xl font-semibold text-left text-blue-400 pt-4">({this.state.filterResult.length + '/' + this.state.students.length})</h2>
                <div className="w-full grid grid-cols-4 gap-x-8 font-sans placeholder-gray-200 bg-white border-2 border-blue-100 p-4 mt-4">
                    {this.state.filterResult.map((student, index) =>
                        <StudentsList key={student.key} student={student}
                            filter={this.state.filter} category={this.state.category}
                            examId={this.state.examId} examType={this.state.examType}
                            index={index} filterPage={true} />)}
                </div>
            </div>
        )
    }
}

export default FilterPage