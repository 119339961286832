import React, { Component } from "react";

class ExamToTake extends Component {
    async onSubmit(event) {
        event.preventDefault();
        let exam = this.props.exam;
        let category = exam.category;
        let examId = exam.examId;
        let examName = exam.examName;
        if (this.props.isAdd) {
            this.props.onAdd(category, examId, examName);
        } else {
            this.props.onRemove(category, examId, examName);
        }
    }

    render() {
        return (
            <div>
                <div className="w-full inline-flex grid grid-cols-6 gap-x-2 px-2 py-2 shadow-md capitalize">
                    <label className="col-span-5">
                        {this.props.exam.examName}
                    </label>
                    {this.props.isAdd !== null &&
                        <button className={this.props.isAdd ?
                            "w-full max-h-8 place-self-center col-span-1 focus:outline-none rounded-full hover:bg-green-500 border-2"
                            :
                            "w-full max-h-8 place-self-center col-span-1 focus:outline-none rounded-full hover:bg-red-500 border-2"}
                            onClick={(event) => { this.onSubmit(event) }}>
                            {this.props.isAdd ? "+" : "-"}
                        </button>
                    }
                </div>
            </div>
        )
    }
}

export default ExamToTake