import React, { Component } from "react";
import Moment from 'react-moment';
import { database } from "../../Database/Firebase";

class StudentsListEditExam extends Component {

    constructor(props) {
        super(props)
        this.state = {
            certificateGiven: this.props.certificateGiven,
            certificateGivenButtonClassName: "ml-2 rounded-full w-10 h-10 focus:outline-none bg-medal-b bg-contain bg-no-repeat hover:bg-lime-400 border"
        }
    }


    handleCertificateGivenButtonClick(event) {
        event.preventDefault();
        var studentPassedTaxenExamRef = database.ref(
            '/students/' + this.props.studentKey + '/taken_exams/' + this.props.exam.key);
        studentPassedTaxenExamRef.update({ certificateGiven: !this.state.certificateGiven });
        if (this.state.certificateGiven) {
            this.setState({
                certificateGivenButtonClassName:
                    "ml-2 rounded-full w-10 h-10 focus:outline-none bg-medal-b bg-contain bg-no-repeat hover:bg-lime-400 border"
            });
        } else {
            this.setState({
                certificateGivenButtonClassName:
                    "ml-2 rounded-full w-10 h-10 focus:outline-none bg-medal-c bg-contain bg-no-repeat hover:bg-lime-400 border", certificateGivenButtonBorderedClassName: "ml-2 rounded-full w-10 h-10 focus:outline-none bg-medal-b bg-contain bg-no-repeat hover:bg-lime-400 border"
            });
        }

        this.setState({ certificateGiven: !this.state.certificateGiven })

    }

    componentDidMount() {
        if (this.state.certificateGiven) {
            this.setState({
                certificateGivenButtonClassName:
                    "ml-2 rounded-full w-10 h-10 focus:outline-none bg-medal-b bg-contain bg-no-repeat hover:bg-lime-400 border"
            });
        } else {
            this.setState({
                certificateGivenButtonClassName:
                    "ml-2 rounded-full w-10 h-10 focus:outline-none bg-medal-c bg-contain bg-no-repeat hover:bg-lime-400 border", certificateGivenButtonBorderedClassName: "ml-2 rounded-full w-10 h-10 focus:outline-none bg-medal-b bg-contain bg-no-repeat hover:bg-lime-400 border"
            });
        }
    }

    render() {
        if (this.props.exam.status === 'none') {
            return (
                <div className=" my-6 mx-auto h-auto font-semibold py-2 block w-full rounded-2xl bg-gray-100 border-transparent text-center">
                    {this.props.exam.type.substring(0, 50)} - <Moment format="DD.MM.YYYY">{this.props.exam.date}</Moment>
                </div>
            )
        } else if (this.props.exam.status === 'passed') {
            return (
                <div className="my-6 mx-auto h-auto font-semibold py-2 px-4 block w-full rounded-2xl bg-lime-500 border-transparent justify-center flex">
                    <div className="w-5/6 h-auto text-center">
                        {this.props.exam.type.substring(0, 50)} - <Moment format="DD.MM.YYYY">{this.props.exam.date}</Moment> (P)
                    </div>
                    <button className={this.state.certificateGivenButtonClassName}
                        onClick={(event) => {
                            this.handleCertificateGivenButtonClick(event)
                        }}></button>
                </div>
            )
        } else if (this.props.exam.status === 'failed') {
            return (
                <div className=" my-6 mx-auto h-auto font-semibold py-2 block w-full rounded-2xl bg-red-500 border-transparent text-center">
                    {this.props.exam.type.substring(0, 50)} - <Moment format="DD.MM.YYYY">{this.props.exam.date}</Moment> (F)
                </div>
            )
        }

    }
}

export default StudentsListEditExam