import React, { Component } from "react";
import { Link } from "react-router-dom";
import { auth } from "../../Database/Firebase";
import InvalidAuth from "../Authentication/InvalidAuth"

class CertificatedStudentsList extends Component {

    constructor() {
        super();
        this.state = {
            isUserSignedIn: false
        }
    }

    componentDidMount() {
        auth.onAuthStateChanged((user) => {
            if (user) {
                this.setState({ isUserSignedIn: true });
            } else {
                this.setState({ isUserSignedIn: false });
            }
        });
    }

    render() {
        if (!this.state.isUserSignedIn) {
            return (
                <InvalidAuth></InvalidAuth>
            )
        }
        return (
            <div>
                < Link to={{
                    pathname: `/certificated/profile/${this.props.student.key}`,
                    state: {
                        student: this.props.student
                    }
                }}>
                    <div className="my-6 mx-auto h-12 py-2 block w-full rounded-2xl bg-lime-600 hover:bg-lime-500 text-white outline-none text-center">
                        {this.props.student.value.name} {this.props.student.value.surname}
                    </div>
                </Link >
            </div >
        )
    }
}

export default CertificatedStudentsList