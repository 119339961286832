import React, { Component } from "react";
import Moment from 'react-moment';

class CertificatedStudentsListStudentExams extends Component {

    render() {
        if (this.props.exam.status === 'passed') {
            return (
                <div className=" my-6 mx-auto h-auto font-semibold py-2 block w-full rounded-2xl bg-lime-500 border-transparent text-center">
                    {(() => {
                        if (this.props.exam.category === 'module_easa') {
                            return (
                                <div>
                                    {this.props.exam.type}
                                    <p><Moment format="DD-MMMM-YYYY">{this.props.exam.date}</Moment> (P)</p>
                                </div>
                            )
                        } else if (this.props.exam.category === 'module_easa_sghm') {
                            return (
                                <div>
                                    {this.props.exam.type}
                                    <p><Moment format="DD-MMMM-YYYY">{this.props.exam.date}</Moment> (P)</p>
                                </div>
                            )
                        } else {
                            return (
                                <div>
                                    {this.props.exam.type}
                                    <p><Moment format="DD-MMMM-YYYY">{this.props.exam.date}</Moment> (P)</p>
                                </div>
                            )
                        }
                    })()}
                </div>
            )
        } else if (this.props.exam.status === 'failed') {
            return (
                <div className=" my-6 mx-auto h-auto font-semibold py-2 block w-full rounded-2xl bg-red-500 border-transparent text-center">
                    {this.props.exam.category.replace(/_/g, " ")} - {this.props.exam.type}
                    <p><Moment format="DD-MMMM-YYYY">{this.props.exam.date}</Moment> (F)</p>
                </div>
            )
        }
    }
}

export default CertificatedStudentsListStudentExams