import React, { Component } from "react";
import { Link } from "react-router-dom";
import ModalImage from "react-modal-image";
import { database, auth, storage } from "../../Database/Firebase";
import ReactToPrint from "react-to-print";
import home from "../../Styles/Home/home-icon-purple.png";
import back from "../../Styles/Icon/icon-back.png";
import StudentsListEditExam from "./StudentsListEditExam";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'
import InvalidAuth from "../Authentication/InvalidAuth"
import BounceLoader from "react-spinners/BounceLoader";
import { css } from "@emotion/core";
import ExamToTake from '../CreateStudent/ExamToTake';

class PrintPage extends Component {

    constructor(props) {
        super(props)
        const person = this.props.studentProps;
        this.state = {
            disable: true,
            key: person.key,
            name: person.value.name,
            surname: person.value.surname,
            email: person.value.email,
            phone: person.value.phone,
            birthdate: person.value.birthdate,
            birthplace: person.value.birthplace,
            passport: null,
            passportKey: 'input_file',
            downloadURL: person.value.passport,
            notes: person.value.notes,
            exams: [],
            certificate: '',
            isLoading: false,
            editCheck: false,
            moduleEasa: [],
            moduleEasaShgm: [],
            trainings: [],
            categories: [],
            filteredExams: [],
            examsToTakeEasa: [],
            examsToTakeEasaShgm: [],
            examsToTakeTraining: [],
            isEasaChecked: true,
            isEasaShgmChecked: false,
            isTrainingChecked: false
        }
        this.onLoadExams();
        this.onLoadExamInfo();
    }

    isEmpty(str) {
        return (!str || 0 === str.length);
    }

    clearPassportInput() {
        this.setState({ passportKey: Date.now() });
    }

    async onLoadExams() {
        const key = this.state.key;
        await database.ref(`students/${key}/taken_exams/`).once('value').then((snapshot) => {
            let temp = [];
            snapshot.forEach((keySnaphot) => {
                temp.push(keySnaphot.val())
            });
            this.setState({ exams: temp })
        }).catch((e) => {
            console.log(e)
        })
    }

    async onLoadExamInfo() {

        await database.ref('students/' + this.state.key + '/exams_to_take').once('value').then((snapshot) => {
            let i = 0;
            let tempCategories = [];
            let tempModuleEasa = [];
            let tempModuleEasaShgm = [];
            let tempTrainings = [];

            snapshot.forEach((categorySnapshot) => {
                tempCategories.push(categorySnapshot.key.replace(/_/g, " "))
                categorySnapshot.forEach((examTypeSnaphot) => {
                    if (i === 0) {
                        tempModuleEasa.push({ examId: examTypeSnaphot.key, examName: examTypeSnaphot.val(), category: 'module_easa' })
                    }
                    else if (i === 1) {
                        tempModuleEasaShgm.push({ examId: examTypeSnaphot.key, examName: examTypeSnaphot.val(), category: 'module_easa_shgm' })
                    }
                    else if (i === 2) {
                        tempTrainings.push({ examId: examTypeSnaphot.key, examName: examTypeSnaphot.val(), category: 'training' })
                    }
                });
                i++;
            });
            tempModuleEasa.sort((a, b) => a.examName.localeCompare(b.examName) === 1 ? 1 : -1);
            tempModuleEasaShgm.sort((a, b) => a.examName.localeCompare(b.examName) === 1 ? 1 : -1);
            tempTrainings.sort((a, b) => a.examName.localeCompare(b.examName) === 1 ? 1 : -1);
            this.setState({ examsToTakeEasa: tempModuleEasa })
            this.setState({ examsToTakeEasaShgm: tempModuleEasaShgm })
            this.setState({ examsToTakeTraining: tempTrainings })
        }).catch((e) => {
            console.log(e)
        })

        await database.ref('exams/').once('value').then((snapshot) => {
            let i = 0;
            let tempCategories = [];
            let tempModuleEasa = [];
            let tempModuleEasaShgm = [];
            let tempTrainings = [];

            snapshot.forEach((categorySnapshot) => {
                tempCategories.push(categorySnapshot.key.replace(/_/g, " "))
                categorySnapshot.forEach((examTypeSnaphot) => {
                    if (i === 0) {
                        let exists = false;
                        for (let j = 0; j < this.state.examsToTakeEasa.length; j++) {
                            if (this.state.examsToTakeEasa[j].examId === examTypeSnaphot.key) {
                                exists = true;
                                break;
                            }
                        }
                        if (!exists)
                            tempModuleEasa.push({ examId: examTypeSnaphot.key, examName: examTypeSnaphot.val(), category: 'module_easa' })
                    }
                    else if (i === 1) {
                        let exists = false;
                        for (let j = 0; j < this.state.examsToTakeEasaShgm.length; j++) {
                            if (this.state.examsToTakeEasaShgm[j].examId === examTypeSnaphot.key) {
                                exists = true;
                                break;
                            }
                        }
                        if (!exists)
                            tempModuleEasaShgm.push({ examId: examTypeSnaphot.key, examName: examTypeSnaphot.val(), category: 'module_easa_shgm' })
                    }
                    else if (i === 2) {
                        let exists = false;
                        for (let j = 0; j < this.state.examsToTakeTraining.length; j++) {
                            if (this.state.examsToTakeTraining[j].examId === examTypeSnaphot.key) {
                                exists = true;
                                break;
                            }
                        }
                        if (!exists)
                            tempTrainings.push({ examId: examTypeSnaphot.key, examName: examTypeSnaphot.val(), category: 'training' })
                    }
                });
                i++;
            });
            tempModuleEasa.sort((a, b) => a.examName.localeCompare(b.examName) === 1 ? 1 : -1);
            tempModuleEasaShgm.sort((a, b) => a.examName.localeCompare(b.examName) === 1 ? 1 : -1);
            tempTrainings.sort((a, b) => a.examName.localeCompare(b.examName) === 1 ? 1 : -1);
            this.setState({ categories: tempCategories })
            this.setState({ moduleEasa: tempModuleEasa })
            this.setState({ moduleEasaShgm: tempModuleEasaShgm })
            this.setState({ trainings: tempTrainings })
            this.setState({ filteredExams: this.state.moduleEasa })
        }).catch((e) => {
            console.log(e)
        })


    }

    async onLoadStudent() {
        const key = this.state.key;
        let temp = '';
        await database.ref(`students/${key}`).once('value').then((snapshot) => {
            temp = snapshot.val();
        }).catch((e) => {
            console.log(e)
        })
        await this.setState({ certificate: temp })
    }

    async saveInfo() {
        if (this.isEmpty(this.state.name) || this.isEmpty(this.state.surname)) {
            this.showPopup("All the required fields must be filled (*)");
            return;
        }

        this.setState({ isLoading: true })

        let isPassportSaved = true;
        let passportFile = this.state.passport;
        let studentStorageRef = storage.ref('students/' + this.state.key);
        if (this.state.passport !== null) {
            await studentStorageRef.put(passportFile).then((task) => {
                if (!task) {
                    this.showPopup("Couldn't save student passport image!");
                    this.setState({ isLoading: false })
                    isPassportSaved = false;
                }
            });
        }

        if (isPassportSaved) {
            if (this.state.passport !== null) {
                await studentStorageRef.getDownloadURL().then((downloadURL) => {
                    this.state.downloadURL = downloadURL
                });
            }
            let isStudentInfoSaved = true;
            let examsToTakeEasaData = {};
            for (let i = 0; i < this.state.examsToTakeEasa.length; i++) {
                let examId = this.state.examsToTakeEasa[i].examId;
                let examName = this.state.examsToTakeEasa[i].examName;
                examsToTakeEasaData[examId] = examName;
            }

            let examsToTakeEasaShgmData = {};
            for (let i = 0; i < this.state.examsToTakeEasaShgm.length; i++) {
                let examId = this.state.examsToTakeEasaShgm[i].examId;
                let examName = this.state.examsToTakeEasaShgm[i].examName;
                examsToTakeEasaShgmData[examId] = examName;
            }

            let examsToTakeTrainingData = {};
            for (let i = 0; i < this.state.examsToTakeTraining.length; i++) {
                let examId = this.state.examsToTakeTraining[i].examId;
                let examName = this.state.examsToTakeTraining[i].examName;
                examsToTakeTrainingData[examId] = examName;
            }
            await database.ref('students/' + this.state.key).update({
                name: this.state.name.toLowerCase(),
                surname: this.state.surname.toLowerCase(),
                email: this.state.email.toLowerCase(),
                phone: this.state.phone.toLowerCase(),
                birthdate: this.state.birthdate.toLowerCase(),
                birthplace: this.state.birthplace.toLowerCase(),
                notes: this.state.notes.toLowerCase(),
                passport: this.state.downloadURL,
                exams_to_take: {
                    module_easa: examsToTakeEasaData,
                    module_easa_shgm: examsToTakeEasaShgmData,
                    training: examsToTakeTrainingData
                }
            }, (error) => {
                if (error) {
                    console.log(error);
                    isStudentInfoSaved = false;
                    this.setState({ isLoading: false })
                } else {
                    this.showEditPopup(this.state.name.toLowerCase(), this.state.surname.toLowerCase());
                }
            });
            if (isStudentInfoSaved) {
                this.setState({ isLoading: false })
                this.clearPassportInput();
            }
        }
    }

    async handleEditClick(event) {
        event.preventDefault();
        this.setState({ disable: !this.state.disable })
        await this.setState({ editCheck: !this.state.editCheck })
        if (!this.state.editCheck) {
            this.saveInfo();
        }
    }

    async handleDeleteClick() {
        await this.state.exams.forEach((exam) => {
            database.ref('exam_dates/' + exam.key + '/exam_takers/' + exam.order)
                .remove().then((error) => {
                    if (error) {
                        console.log(error)
                    }
                });
        });
        await database.ref('students/' + this.state.key).remove().then((error) => {
            if (error) {
                console.log(error)
            }
        });

        let deleteRef = storage.ref('students').child(this.state.key);

        // Delete the file
        await deleteRef.delete().then(() => {
        }).catch((error) => {
            console.log(error)
        });
        this.props.history.push("/students");
    }

    async handleCertificateClick() {
        await this.onLoadStudent();
        await this.state.exams.forEach((exam) => {
            database.ref('exam_dates/' + exam.key + '/exam_takers/' +
                this.state.key).update({ certificated: true });
        });
        await database.ref('certificated_students/' + this.state.key).set(this.state.certificate);
        await database.ref('students/' + this.state.key).remove().then((error) => {
            if (error) {
                console.log(error)
            }
        });
        this.props.history.push("/students");
    }

    showEditPopup(name, surname) {
        confirmAlert({
            title: 'EDIT STUDENT',
            message: 'Changes saved for:',
            customUI: ({ title, message, onClose }) =>
                <div className="w-80 px-10 py-8 bg-white font-family:Arial text-gray-600 text-center border rounded border-gray-600">
                    <h3 className="w-full mb-1">{title}</h3>
                    <h4 className="w-full mb-3">{message}<br />{name.toUpperCase()} {surname.toUpperCase()}</h4>
                    <div>
                        <button className="w-full rounded text-white bg-lime-400 hover:bg-lime-600 focus:outline-none"
                            onClick={() => { onClose(); }}>
                            OK
                        </button>
                    </div>
                </div>
        });
    };

    showDeletePopup(event, name, surname) {
        event.preventDefault();
        confirmAlert({
            title: 'DELETE STUDENT',
            message: 'Are you sure to delete:',
            customUI: ({ title, message, onClose }) =>
                <div className="w-80 px-10 py-8 bg-white font-family:Arial text-gray-600 text-center border rounded border-gray-600">
                    <h3 className="w-full mb-1">{title}</h3>
                    <h4 className="w-full mb-3">{message}<br />{name.toUpperCase()} {surname.toUpperCase()}</h4>
                    <div className="grid grid-cols-2 gap-x-2">
                        <button className="w-full rounded text-white bg-lime-400 hover:bg-lime-600 focus:outline-none"
                            onClick={(event) => { this.handleDeleteClick(event); onClose(); }}>
                            YES
                        </button>
                        <button className="w-full rounded text-white bg-red-400 hover:bg-red-600 focus:outline-none"
                            onClick={(event) => { onClose(); }}>
                            NO
                        </button>
                    </div>
                </div>
        });
    };

    showCertificatePopup(event, name, surname) {
        event.preventDefault();
        confirmAlert({
            title: 'CERTIFICATE STUDENT',
            message: 'Are you sure to certificate:',
            customUI: ({ title, message, onClose }) =>
                <div className="w-80 px-10 py-8 bg-white font-family:Arial text-gray-600 text-center border rounded border-gray-600">
                    <h3 className="w-full mb-1">{title}</h3>
                    <h4 className="w-full mb-3">{message}<br />{name.toUpperCase()} {surname.toUpperCase()}</h4>
                    <div className="grid grid-cols-2 gap-x-2">
                        <button className="w-full rounded text-white bg-lime-400 hover:bg-lime-600 focus:outline-none"
                            onClick={(event) => { this.handleCertificateClick(); onClose(); }}>
                            YES
                        </button>
                        <button className="w-full rounded text-white bg-red-400 hover:bg-red-600 focus:outline-none"
                            onClick={(event) => { onClose(); }}>
                            NO
                        </button>
                    </div>
                </div>
        });
    };

    showPopup(popupMessage) {
        confirmAlert({
            title: 'CREATE STUDENT',
            message: popupMessage,
            customUI: ({ title, message, onClose }) =>
                < div className="w-80 px-10 py-8 bg-white font-family:Arial text-gray-600 text-center border rounded border-gray-600" >
                    <h3 className="w-full mb-1">{title}</h3>
                    <h4 className="w-full mb-3">{message}</h4>
                    <button
                        className="w-5/6 rounded text-white bg-lime-400 hover:bg-lime-600 focus:outline-none"
                        onClick={() => { onClose() }}
                    >
                        OK</button>
                </div >
        })
    };

    // where we change values with edit 
    onChangeHandler = (event) => {
        const { name, value } = event.currentTarget;
        if (name === 'name') {
            this.setState({ name: value })
        }
        else if (name === 'surname') {
            this.setState({ surname: value })
        }
        else if (name === 'email') {
            this.setState({ email: value })
        }
        else if (name === 'phone') {
            this.setState({ phone: value })
        }
        else if (name === 'birthdate') {
            this.setState({ birthdate: value })
        }
        else if (name === 'birthplace') {
            this.setState({ birthplace: value })
        }
        else if (name === 'passport') {
            this.setState({ passport: event.target.files[0] })
        }
        else if (name === 'notes') {
            this.setState({ notes: value })
        }

    };

    onEasaClick(event) {
        event.preventDefault();
        this.setState({ isEasaChecked: true })
        this.setState({ isEasaShgmChecked: false });
        this.setState({ isTrainingChecked: false });
        this.setState({ filteredExams: this.state.moduleEasa })
    }

    onEasaShgmClick(event) {
        event.preventDefault();
        this.setState({ isEasaShgmChecked: true })
        this.setState({ isEasaChecked: false });
        this.setState({ isTrainingChecked: false });
        this.setState({ filteredExams: this.state.moduleEasaShgm })
    }

    onTrainingClick(event) {
        event.preventDefault();
        this.setState({ isTrainingChecked: true })
        this.setState({ isEasaShgmChecked: false });
        this.setState({ isEasaChecked: false });
        this.setState({ filteredExams: this.state.trainings })
    }

    onAdd = (category, examId, examName) => {
        if (category === 'module_easa') {
            this.state.examsToTakeEasa.push({ examId: examId, examName: examName, category: category });
            this.state.examsToTakeEasa.sort((a, b) => a.examName.localeCompare(b.examName) === 1 ? 1 : -1)
            this.setState({ examsToTakeEasa: this.state.examsToTakeEasa });
            for (let i = 0; i < this.state.moduleEasa.length; i++) {
                if (this.state.moduleEasa[i].examId === examId) {
                    this.state.moduleEasa.splice(i, 1);
                    break;
                }
            }
            this.state.moduleEasa.sort((a, b) => a.examName.localeCompare(b.examName) === 1 ? 1 : -1)
        } else if (category === 'module_easa_shgm') {
            this.state.examsToTakeEasaShgm.push({ examId: examId, examName: examName, category: category });
            this.state.examsToTakeEasaShgm.sort((a, b) => a.examName.localeCompare(b.examName) === 1 ? 1 : -1)
            this.setState({ examsToTakeEasaShgm: this.state.examsToTakeEasaShgm });
            for (let i = 0; i < this.state.moduleEasaShgm.length; i++) {
                if (this.state.moduleEasaShgm[i].examId === examId) {
                    this.state.moduleEasaShgm.splice(i, 1);
                    break;
                }
            }
            this.state.moduleEasaShgm.sort((a, b) => a.examName.localeCompare(b.examName) === 1 ? 1 : -1)
        } else if (category === 'training') {
            this.state.examsToTakeTraining.push({ examId: examId, examName: examName, category: category });
            this.state.examsToTakeTraining.sort((a, b) => a.examName.localeCompare(b.examName) === 1 ? 1 : -1)
            this.setState({ examsToTakeTraining: this.state.examsToTakeTraining });
            for (let i = 0; i < this.state.trainings.length; i++) {
                if (this.state.trainings[i].examId === examId) {
                    this.state.trainings.splice(i, 1);
                    break;
                }
            }
            this.state.trainings.sort((a, b) => a.examName.localeCompare(b.examName) === 1 ? 1 : -1)
        }
    }

    onRemove = (category, examId, examName) => {
        if (category === 'module_easa') {
            for (let i = 0; i < this.state.examsToTakeEasa.length; i++) {
                if (this.state.examsToTakeEasa[i].examId === examId) {
                    this.state.examsToTakeEasa.splice(i, 1);
                    break;
                }
            }
            this.state.moduleEasa.push({ examId: examId, examName: examName, category: category });
            this.state.examsToTakeEasa.sort((a, b) => a.examName.localeCompare(b.examName) === 1 ? 1 : -1)
            this.state.moduleEasa.sort((a, b) => a.examName.localeCompare(b.examName) === 1 ? 1 : -1)
            this.setState({ examsToTakeEasa: this.state.examsToTakeEasa });
        } else if (category === 'module_easa_shgm') {
            for (let i = 0; i < this.state.examsToTakeEasaShgm.length; i++) {
                if (this.state.examsToTakeEasaShgm[i].examId === examId) {
                    this.state.examsToTakeEasaShgm.splice(i, 1);
                    break;
                }
            }
            this.state.moduleEasaShgm.push({ examId: examId, examName: examName, category: category });
            this.state.examsToTakeEasaShgm.sort((a, b) => a.examName.localeCompare(b.examName) === 1 ? 1 : -1)
            this.state.moduleEasaShgm.sort((a, b) => a.examName.localeCompare(b.examName) === 1 ? 1 : -1)
            this.setState({ examsToTakeEasaShgm: this.state.examsToTakeEasaShgm });
        } else if (category === 'training') {
            for (let i = 0; i < this.state.examsToTakeTraining.length; i++) {
                if (this.state.examsToTakeTraining[i].examId === examId) {
                    this.state.examsToTakeTraining.splice(i, 1);
                    break;
                }
            }
            this.state.trainings.push({ examId: examId, examName: examName, category: category });
            this.state.examsToTakeTraining.sort((a, b) => a.examName.localeCompare(b.examName) === 1 ? 1 : -1)
            this.state.trainings.sort((a, b) => a.examName.localeCompare(b.examName) === 1 ? 1 : -1)
            this.setState({ examsToTakeTraining: this.state.examsToTakeTraining });
        }
    }

    render() {
        return (
            <div className="w-full break-words px-20 pb-8">
                {/* HEADER */}
                <div className="h-1/6 mb-4 flex font-sans text-2xl font-semibold tracking-wider text-left pt-8 text-purple-400 border-b-2 border-purple-300 capitalize">
                    <h2 className="w-1/2 pt-4">{this.state.name} {this.state.surname}</h2>
                    <div className="w-1/2 bg-logo bg-contain bg-no-repeat bg-right" />
                </div>
                {/* Edit / Delete / Save / Certificate Options  */}
                <div className="mt-4 mb-6 h-10 items-center flex">
                    <p className="w-full text-xl underline text-purple-500">PERSONAL INFO</p>
                    <button
                        className={!this.state.editCheck ?
                            "rounded-full mx-4 mt-2 h-8 w-1/6 focus:outline-none bg-contain bg-center bg-no-repeat bg-edit bg-transparent hover:bg-purple-300"
                            :
                            "rounded-full mx-4 mt-2 h-8 w-1/6 focus:outline-none bg-contain bg-center bg-no-repeat bg-tick bg-transparent hover:bg-purple-300"
                        }
                        onClick={(event) => {
                            this.handleEditClick(event)
                        }}
                        value=""
                    />
                    <button
                        className="rounded-full mx-4 mt-2 h-8 w-1/6 focus:outline-none bg-transparent bg-contain bg-center bg-no-repeat bg-cancel hover:bg-purple-300"
                        onClick={(event) => {
                            this.showDeletePopup(
                                event,
                                this.state.name,
                                this.state.surname)
                        }}
                        value=""
                    />
                    <button
                        className="rounded-full mx-4 mt-2 h-8 w-1/6 focus:outline-none bg-transparent bg-contain bg-center bg-no-repeat bg-certificate hover:bg-purple-300"
                        onClick={(event) => {
                            this.showCertificatePopup(
                                event,
                                this.state.name,
                                this.state.surname)
                        }}
                        value=""
                    />
                </div>

                <div className="w-full mt-4 font-sans text-lg">
                    {/* PERSONAL INFO */}
                    <div className="w-full my-4 flex font-sans">
                        <form className='w-full px-10 grid grid-rows-2 grid-cols-4 gap-x-4 gap-y-0'>
                            <label className="block">
                                <span className="text-purple-400 text-xl">Name</span>
                                <input className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 capitalize"
                                    type="text"
                                    name="name"
                                    id="name"
                                    disabled={this.state.disable}
                                    value={this.state.name}
                                    onChange={(event) => this.onChangeHandler(event)}></input>
                            </label>
                            <label className="block">
                                <span className="text-purple-400 text-xl">Surname</span>
                                <input className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 capitalize"
                                    type="text"
                                    name="surname"
                                    id="surname"
                                    disabled={this.state.disable}
                                    value={this.state.surname}
                                    onChange={(event) => this.onChangeHandler(event)}></input>
                            </label>
                            <label className="block">
                                <span className="text-purple-400 text-xl">Email</span>
                                <input className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0"
                                    type="text"
                                    name="email"
                                    id="email"
                                    disabled={this.state.disable}
                                    value={this.state.email}
                                    onChange={(event) => this.onChangeHandler(event)}></input>
                            </label>
                            <label className="block">
                                <span className="text-purple-400 text-xl">Phone</span>
                                <input className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0"
                                    type="text"
                                    name="phone"
                                    id="phone"
                                    disabled={this.state.disable}
                                    value={this.state.phone}
                                    onChange={(event) => this.onChangeHandler(event)}></input>
                            </label>
                            <label className="block">
                                <span className="text-purple-400 text-xl">Birthdate</span>
                                <input
                                    className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 capitalize"
                                    type="date"
                                    name="birthdate"
                                    id="birthdate"
                                    disabled={this.state.disable}
                                    value={this.state.birthdate}
                                    onChange={(event) => this.onChangeHandler(event)}
                                />
                            </label>
                            <label className="block">
                                <span className="text-purple-400 text-xl">Birthplace</span>
                                <input
                                    className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 capitalize"
                                    type="text"
                                    name="birthplace"
                                    id="birthplace"
                                    disabled={this.state.disable}
                                    value={this.state.birthplace}
                                    onChange={(event) => this.onChangeHandler(event)}
                                />
                            </label>
                            <div className="block h-full w-full">
                                <span className="text-purple-400 text-xl">Passport Image</span>
                                <div className="block h-full w-full flex">
                                    <ModalImage
                                        className="h-24 w-24"
                                        small={this.state.downloadURL}
                                        large={this.state.downloadURL}
                                        alt="Passport Image" />
                                    <input
                                        type="file"
                                        key={this.state.passportKey}
                                        className={this.state.disable ?
                                            "mt-1 block h-10 ml-4 rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 capitalize invisible"
                                            : "mt-1 block h-10 ml-4 rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 capitalize visible"
                                        }
                                        name="passport"
                                        id="passport"
                                        onChange={(event) => this.onChangeHandler(event)}
                                    />
                                </div>
                            </div>
                            <label className={this.props.noteVisible ? "block visible" : "block invisible"}>
                                <span className="text-purple-400 text-xl">Notes</span>
                                <textarea className="mt-1 block w-full h-100 rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 capitalize"
                                    type="textarea"
                                    name="notes"
                                    id="notes"
                                    disabled={this.state.disable}
                                    value={this.state.notes}
                                    onChange={(event) => this.onChangeHandler(event)}></textarea>
                            </label>
                        </form>
                    </div>

                    {this.props.examsToTakeVisible &&
                        <p className="w-full text-xl underline text-purple-500">EXAMS TO TAKE</p>
                    }
                    {this.props.examsToTakeVisible &&
                        <div className={this.state.editCheck ?
                            "w-full my-4 py-4 px-2 grid grid-cols-4 gap-x-1 gap-y-0 col-span-4 border-purple-100 border-2"
                            :
                            "w-full my-4 py-4 px-2 grid grid-cols-3 gap-x-1 gap-y-0 col-span-4 border-purple-100 border-2"}>
                            {this.state.editCheck && <div className="w-full px-4 py-2 shadow-md col-span-1">
                                <div className="w-full inline-flex gap-x-2 px-1 py-2">
                                    <button className={!this.state.isEasaChecked ?
                                        "w-1/3 focus:outline-none rounded-full hover:bg-purple-300 border-2 text-sm"
                                        :
                                        "w-1/3 focus:outline-none rounded-full hover:bg-purple-300 border-2 bg-purple-500 text-sm"}
                                        onClick={(event) => { this.onEasaClick(event) }}>
                                        EASA
                                    </button>
                                    <button className={!this.state.isEasaShgmChecked ?
                                        "w-1/3 focus:outline-none rounded-full hover:bg-purple-300 border-2 text-sm"
                                        :
                                        "w-1/3 focus:outline-none rounded-full hover:bg-purple-300 border-2 bg-purple-500 text-sm"}
                                        onClick={(event) => { this.onEasaShgmClick(event) }}>
                                        EASA SHGM
                                    </button>
                                    <button className={!this.state.isTrainingChecked ?
                                        "w-1/3 focus:outline-none rounded-full hover:bg-purple-300 border-2 text-sm"
                                        :
                                        "w-1/3 focus:outline-none rounded-full hover:bg-purple-300 border-2 bg-purple-500 text-sm"}
                                        onClick={(event) => { this.onTrainingClick(event) }}>
                                        TRAINING
                                    </button>
                                </div>
                                <div className="overflow-y-auto">
                                    {this.state.filteredExams
                                        .map((exam, index) =>
                                            <ExamToTake
                                                key={exam.examId}
                                                exam={exam}
                                                index={index}
                                                isAdd={true}
                                                onAdd={this.onAdd}
                                                onRemove={this.onRemove}
                                            />)
                                    }
                                </div>
                            </div>}
                            <div className="w-full px-4 py-2 shadow-md col-span-1">
                                <h3 className="w-full pb-4 uppercase underline font-semibold text-purple-500">EASA  - ({this.state.examsToTakeEasa.length})</h3>
                                <div className="overflow-y-auto">
                                    {this.state.editCheck ?
                                        this.state.examsToTakeEasa
                                            .map((exam, index) =>
                                                <ExamToTake
                                                    key={exam.examId}
                                                    exam={exam}
                                                    index={index}
                                                    isAdd={false}
                                                    onAdd={this.onAdd}
                                                    onRemove={this.onRemove}
                                                />)
                                        :
                                        this.state.examsToTakeEasa
                                            .map((exam, index) =>
                                                <ExamToTake
                                                    key={exam.examId}
                                                    exam={exam}
                                                    index={index}
                                                />)
                                    }
                                </div>
                            </div>
                            <div className="w-full px-4 py-2 shadow-md col-span-1">
                                <h3 className="pb-4 uppercase underline font-semibold text-purple-500 place-self-center">EASA SHGM  - ({this.state.examsToTakeEasaShgm.length})</h3>
                                <div className="overflow-y-auto">
                                    {this.state.editCheck ?
                                        this.state.examsToTakeEasaShgm
                                            .map((exam, index) =>
                                                <ExamToTake
                                                    key={exam.examId}
                                                    exam={exam}
                                                    index={index}
                                                    isAdd={false}
                                                    onAdd={this.onAdd}
                                                    onRemove={this.onRemove}
                                                />)
                                        :
                                        this.state.examsToTakeEasaShgm
                                            .map((exam, index) =>
                                                <ExamToTake
                                                    key={exam.examId}
                                                    exam={exam}
                                                    index={index}
                                                />)
                                    }
                                </div>
                            </div>
                            <div className="w-full px-4 py-2 shadow-md col-span-1">
                                <h3 className="w-full pb-4 uppercase underline font-semibold text-purple-500">TRAINING  - ({this.state.examsToTakeTraining.length})</h3>
                                <div className="overflow-y-auto">
                                    {this.state.editCheck ?
                                        this.state.examsToTakeTraining
                                            .map((exam, index) =>
                                                <ExamToTake
                                                    key={exam.examId}
                                                    exam={exam}
                                                    index={index}
                                                    isAdd={false}
                                                    onAdd={this.onAdd}
                                                    onRemove={this.onRemove}
                                                />)
                                        :
                                        this.state.examsToTakeTraining
                                            .map((exam, index) =>
                                                <ExamToTake
                                                    key={exam.examId}
                                                    exam={exam}
                                                    index={index}
                                                />)
                                    }
                                </div>
                            </div>
                        </div>
                    }

                    <div className="mt-8 w-full inline-flex">
                        <label className="w-full text-xl underline text-purple-500">EXAM INFO</label>
                        <p className="w-full mx-4 rounded-2xl bg-gray-100 border-transparent text-center">No Result</p>
                        <p className="w-full mx-4 rounded-2xl bg-lime-500 border-transparent text-center">Passed</p>
                        <p className="w-full ml-4 rounded-2xl bg-red-500 border-transparent text-center">Failed</p>
                    </div>

                    {/* EXAM INFO */}
                    <div className="h-auto w-full mt-4 px-4 pt-4 pb-8 inline-flex grid grid-cols-3 gap-x-8 gap-y-0 font-sans text-lg border-purple-100 border-2 capitalize">
                        <div className="w-full">
                            <p className="w-full text-l text-center underline text-purple-500">Module Easa & Shgm</p>
                            {this.state.exams.sort((a, b) => a.type.localeCompare(b.type) === 1 ? 1 : -1)
                                .map((exam, index) => {
                                    if (exam.category === 'module_easa_sghm') {
                                        return (
                                            <StudentsListEditExam
                                                key={exam.key}
                                                exam={exam}
                                                index={index}
                                                studentKey={this.state.key} />
                                        )
                                    } else {
                                        return null;
                                    }
                                })}
                        </div>
                        <div className="w-full">
                            <p className="w-full text-l text-center underline text-purple-500">Module Easa</p>
                            {this.state.exams
                                .sort(
                                    (a, b) => a.type.localeCompare(b.type) === 1 ? 1 : -1)
                                .map((exam, index) => {
                                    if (exam.category === 'module_easa') {
                                        return (
                                            <StudentsListEditExam
                                                key={exam.key}
                                                exam={exam}
                                                index={index}
                                                studentKey={this.state.key} />
                                        )
                                    } else {
                                        return null;
                                    }
                                })}
                        </div>
                        <div>
                            <p className="w-full text-l text-center underline text-purple-500">Training</p>
                            {this.state.exams.sort((a, b) => a.type.localeCompare(b.type) === 1 ? 1 : -1)
                                .map((exam, index) => {
                                    if (exam.category === 'training') {
                                        return (
                                            <StudentsListEditExam
                                                key={exam.key}
                                                exam={exam}
                                                index={index}
                                                studentKey={this.state.key} />
                                        )
                                    } else {
                                        return null;
                                    }
                                })}
                        </div>
                    </div>
                </div>
                <BounceLoader
                    color='#8B5CF6'
                    loading={this.state.isLoading}
                    size={150}
                    css={css`
                            display: block;
                            margin: 0 auto;
                            border-color: red;
                            position: absolute;
                            top: calc(50% - 75px);
                            left: calc(50% - 75px);    
                        `} />
            </div >
        )
    }
}

class StudentsListEditStudent extends React.Component {

    constructor() {
        super()
        this.state = {
            noteVisible: false,
            isUserSignedIn: false,
            buttonVisible: false,
            examsToTakeVisible: false
        }
    }

    onShowHideClick(event) {
        event.preventDefault();
        this.setState({ noteVisible: !this.state.noteVisible });
        this.setState({ examsToTakeVisible: !this.state.examsToTakeVisible });
    }

    componentDidMount() {
        auth.onAuthStateChanged((user) => {
            if (user) {
                if (
                    user.email === 'hmc@hmcaviationmaintenance.com' ||
                    user.email === 'gulin.gezelge@gmail.com' ||
                    user.email === 'eliftultay@gmail.com') {
                    this.setState({ isUserSignedIn: true, noteVisible: true, buttonVisible: true, examsToTakeVisible: true });;
                } else if (
                    user.email === 'suleelik@gmail.com' ||
                    user.email === 'hmcaviationsupervisor@gmail.com') {
                    this.setState({ isUserSignedIn: true, noteVisible: false, buttonVisible: false, examsToTakeVisible: true });;
                } else {
                    this.setState({ isUserSignedIn: false, noteVisible: false, buttonVisible: false, examsToTakeVisible: false });
                }
            } else {
                this.setState({ isUserSignedIn: false });
            }
        });
    }

    render() {
        if (!this.state.isUserSignedIn) {
            return (
                <InvalidAuth></InvalidAuth>
            )
        }
        return (
            <div className="w-full px-20">
                <div className="h-16 pt-2 border-b-2 border-purple-300 flex">
                    <div className="ml-auto flex">
                        <button
                            className={this.state.buttonVisible ?
                                this.state.noteVisible ?
                                    'rounded-full w-10 h-10 focus:outline-none bg-invisible bg-contain bg-no-repeat'
                                    : 'rounded-full w-10 h-10 focus:outline-none bg-visible bg-contain bg-no-repeat'
                                : 'rounded-full w-10 h-10 focus:outline-none invisible'}
                            onClick={(event) => { this.onShowHideClick(event) }}></button>
                        <ReactToPrint className=""
                            trigger={() =>
                                <button className="rounded-full ml-10 w-10 h-10 bg-print bg-contain bg-no-repeat"
                                ></button>}
                            content={() => this.componentRef}
                        />
                        <Link to={{
                            pathname: this.props.location.state.returnUrl,
                            state: {
                                filter: this.props.location.state.filter,
                                category: this.props.location.state.category,
                                examType: this.props.location.state.examType,
                                examId: this.props.location.state.examId,
                            }
                        }}>
                            <img className="rounded-full ml-10 w-10 h-10 block border-2 border-gray-500" src={back} alt="back" />
                        </Link>
                        <Link to="/home" className="ml-10">
                            <img className="rounded-full w-10 h-10 block" src={home} alt="home" />
                        </Link>
                    </div>
                </div>
                <PrintPage
                    ref={(el) => (this.componentRef = el)}
                    noteVisible={this.state.noteVisible}
                    examsToTakeVisible={this.state.examsToTakeVisible}
                    studentProps={this.props.location.state.studentProps}
                    history={this.props.history}
                />
            </div>
        );
    }
}

export default StudentsListEditStudent