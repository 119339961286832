import React, { Component } from 'react';
import { Link } from "react-router-dom";

class InvalidAuth extends Component {

    render() {
        return (
            <div className="w-screen h-screen flex">
                <div className="block mx-auto my-auto">
                    <h1 className="w-full text-5xl">You Don't Have Permission to View This Page.</h1> <br />
                    <div className="w-full text-center">
                        <Link to="/" className="text-blue-500 align-middle underline">Go to Login Page</Link>
                    </div>
                </div>
            </div>
        );
    }
}

export default InvalidAuth