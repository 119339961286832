import React, { Component } from "react";
import { Link } from "react-router-dom";
import { auth } from "../../Database/Firebase";
import InvalidAuth from "../Authentication/InvalidAuth"

class StudentsList extends Component {

    constructor() {
        super();
        this.state = {
            isUserSignedIn: false
        }
    }

    componentDidMount() {
        auth.onAuthStateChanged((user) => {
            if (user) {
                this.setState({ isUserSignedIn: true });
            } else {
                this.setState({ isUserSignedIn: false });
            }
        });
    }

    render() {
        if (!this.state.isUserSignedIn) {
            return (
                <InvalidAuth></InvalidAuth>
            )
        }
        let tempReturnUrl = this.props.filterPage ? '/filter' : '/students';
        return (
            <div>
                <Link to={{
                    pathname: `students/profile/${this.props.student.key}`,
                    state: {
                        studentProps: this.props.student,
                        returnUrl: tempReturnUrl,
                        filter: this.props.filter,
                        category: this.props.category,
                        examId: this.props.examId,
                        examType: this.props.examType,
                    }
                }}>
                    <div className={!this.props.filterPage ?
                        "my-3 mx-auto p-2 h-16 block w-full rounded-2xl bg-lila hover:bg-purple-500 text-white font-semibold outline-none text-center border border-purple-500"
                        :
                        this.props.student.takenBefore !== null && this.props.student.takenBefore !== true ?
                            "my-3 mx-auto p-2 h-16 block w-full rounded-2xl bg-blue-400 hover:bg-blue-500 text-white font-semibold outline-none text-center border border-blue-500"
                            :
                            this.props.student.passedBefore !== null && this.props.student.passedBefore !== true ?
                                "my-3 mx-auto p-2 h-16 block w-full rounded-2xl bg-red-400 hover:bg-red-500 text-white font-semibold outline-none text-center border border-red-500"
                                :
                                "my-3 mx-auto p-2 h-16 block w-full rounded-2xl bg-blue-400 hover:bg-blue-500 text-white font-semibold outline-none text-center border border-blue-500"
                    }>
                        {this.props.student.value.name} {this.props.student.value.surname}
                    </div>
                </Link >
            </div >
        )
    }
}

export default StudentsList