import React, { Component } from "react";
import { database } from "../../Database/Firebase";

class ExamsListEditStudent extends Component {

    async onRemove(event) {
        event.preventDefault();
        const exam = this.props.exam;
        const studentKey = this.props.studentKey;
        await database.ref(`exam_dates/${exam.key}/exam_takers/${studentKey}`)
            .remove().then((e) => { });
        await database.ref(`students/${studentKey}/taken_exams/${exam.key}`)
            .remove().then((e) => { });
    }

    render() {
        return (
            <div>
                {!this.props.taker.certificated ?
                    <div className="w-full inline-flex grid grid-cols-5 gap-x-4 px-4 py-2 mb-2 shadow-md capitalize">
                        <label className="col-span-4">
                            {this.props.taker.name} {this.props.taker.surname}
                        </label>
                        <button className="col-span-1 rounded-full hover:bg-red-400 focus:outline-none bg-red-500 bg-contain bg-center bg-no-repeat bg-minus"
                            onClick={(event) => { this.onRemove(event) }}
                        >
                        </button>
                    </div>
                    :
                    <div className="w-full inline-flex px-4 py-2 mb-2 shadow-md bg-gray-200">
                        <label>
                            {this.props.taker.name} {this.props.taker.surname}
                        </label>
                    </div>
                }
            </div>
        )
    }
}

export default ExamsListEditStudent