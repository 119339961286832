import React, { Component } from "react";
import { Link } from "react-router-dom";
import { database, auth, storage } from "../../Database/Firebase";
import home from "../../Styles/Home/home-icon-pink.png";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'
import { css } from "@emotion/core";
import BounceLoader from "react-spinners/BounceLoader";
import InvalidAuth from "../Authentication/InvalidAuth"
import ExamToTake from './ExamToTake';

class CreateStudent extends Component {

    constructor() {
        super()
        this.state = {
            name: '',
            surname: '',
            email: '',
            phone: '',
            birthdate: '',
            birthplace: '',
            passport: null,
            notes: '',
            passportKey: 'input_file',
            downloadURL: '',
            isLoading: false,
            isUserSignedIn: false,
            moduleEasa: [],
            moduleEasaShgm: [],
            trainings: [],
            categories: [],
            filteredExams: [],
            examsToTakeEasa: [],
            examsToTakeEasaShgm: [],
            examsToTakeTraining: [],
            isEasaChecked: true,
            isEasaShgmChecked: false,
            isTrainingChecked: false
        }
        this.onLoadExamInfo();
    }

    isEmpty(str) {
        return (!str || 0 === str.length);
    }

    showPopup(popupMessage) {
        confirmAlert({
            title: 'CREATE STUDENT',
            message: popupMessage,
            customUI: ({ title, message, onClose }) =>
                < div className="w-80 px-10 py-8 bg-white font-family:Arial text-gray-600 text-center border rounded border-gray-600" >
                    <h3 className="w-full mb-1">{title}</h3>
                    <h4 className="w-full mb-3">{message}</h4>
                    <button
                        className="w-5/6 rounded text-white bg-lime-400 hover:bg-lime-600 focus:outline-none"
                        onClick={() => { onClose() }}
                    >
                        OK</button>
                </div >
        })
    };

    showConfirmPopup(listName) {
        confirmAlert({
            title: '!!! ALERT !!!',
            message: `There is already a student with the same name and surname in ${listName}. Are you sure to add?`,
            customUI: ({ title, message, onClose }) =>
                <div className="w-80 px-10 py-8 bg-white font-family:Arial text-gray-600 text-center border rounded border-gray-600">
                    <h3 className="w-full mb-1 text-red-500 text-xl">{title}</h3>
                    <h4 className="w-full mb-3 text-red-500 text-xl">{message}</h4>
                    <div className="grid grid-cols-2 gap-x-2">
                        <button className="w-full rounded text-white bg-lime-400 hover:bg-lime-600 focus:outline-none"
                            onClick={(event) => { this.onSubmit(); onClose(); }}>
                            YES
                        </button>
                        <button className="w-full rounded text-white bg-red-400 hover:bg-red-600 focus:outline-none"
                            onClick={(event) => { onClose(); }}>
                            NO
                        </button>
                    </div>
                </div>
        });
    };

    clearAllInput() {
        for (let i = 0; i < this.state.examsToTakeEasa.length; i++) {
            this.state.moduleEasa.push({ examId: this.state.examsToTakeEasa[i].examId, examName: this.state.examsToTakeEasa[i].examName, category: this.state.examsToTakeEasa[i].category });
        }
        this.state.moduleEasa.sort((a, b) => a.examName.localeCompare(b.examName) === 1 ? 1 : -1)

        for (let i = 0; i < this.state.examsToTakeEasaShgm.length; i++) {
            this.state.moduleEasaShgm.push({ examId: this.state.examsToTakeEasaShgm[i].examId, examName: this.state.examsToTakeEasaShgm[i].examName, category: this.state.examsToTakeEasaShgm[i].category });
        }
        this.state.moduleEasaShgm.sort((a, b) => a.examName.localeCompare(b.examName) === 1 ? 1 : -1)

        for (let i = 0; i < this.state.examsToTakeTraining.length; i++) {
            this.state.trainings.push({ examId: this.state.examsToTakeTraining[i].examId, examName: this.state.examsToTakeTraining[i].examName, category: this.state.examsToTakeTraining[i].category });
        }
        this.state.trainings.sort((a, b) => a.examName.localeCompare(b.examName) === 1 ? 1 : -1)

        this.setState({
            name: '',
            surname: '',
            email: '',
            phone: '',
            birthdate: '',
            birthplace: '',
            passportKey: Date.now(),
            notes: '',
            downloadURL: '',
            examsToTakeEasa: [],
            examsToTakeEasaShgm: [],
            examsToTakeTraining: []
        });
    }

    async handleCheck(event) {
        event.preventDefault();
        await this.studentExists();
    }

    async studentExists() {
        let exists = false;
        var studentListRef = database.ref('/students');
        await studentListRef.once('value', (snapshot) => {
            snapshot.forEach((childSnapshot) => {
                if (childSnapshot.val().name.toLowerCase().replace(/\s/g, '') + childSnapshot.val().surname.toLowerCase().replace(/\s/g, '')
                    === this.state.name.toLowerCase().replace(/\s/g, '') + this.state.surname.toLowerCase().replace(/\s/g, '')) {
                    exists = true;
                    this.showConfirmPopup('students');
                }
            })
        });
        if (!exists) {
            await this.certificatedStudentExists();
        }
    }

    async certificatedStudentExists() {
        let exists = false;
        var certificatedStudentListRef = database.ref('/certificated_students');
        await certificatedStudentListRef.once('value', (snapshot) => {
            snapshot.forEach((childSnapshot) => {
                if (childSnapshot.val().name === this.state.name.toLowerCase()
                    && childSnapshot.val().surname === this.state.surname.toLowerCase()) {
                    exists = true;
                    this.showConfirmPopup('certificated students');
                }
            })
        });
        if (!exists) {
            this.onSubmit();
        }
    }

    async onSubmit(event) {
        if (this.isEmpty(this.state.name) || this.isEmpty(this.state.surname) /* ||
            this.isEmpty(this.state.email) || this.isEmpty(this.state.phone) ||
            this.isEmpty(this.state.birthdate) || this.isEmpty(this.state.birthplace)
            || this.state.passport == null || this.isEmpty(this.state.passport) */) {
            this.showPopup("All the required fields must be filled (*)");
            return;
        }

        this.setState({ isLoading: true })

        // generate a unique key for each student
        let studentsRef = database.ref('students');
        let studentKey = studentsRef.push().key;

        // save passport image to storage
        let passportFile = this.state.passport;
        let studentStorageRef = storage.ref('students/' + studentKey);
        let isPassportSaved = true;
        await studentStorageRef.put(passportFile).then((task) => {
            if (!task) {
                this.showPopup("Couldn't save student passport image!");
                this.setState({ isLoading: false })
                isPassportSaved = false;
            }
        });

        if (isPassportSaved) {
            await studentStorageRef.getDownloadURL().then((downloadURL) => {
                this.state.downloadURL = downloadURL
            });

            let examsToTakeEasaData = {};
            for (let i = 0; i < this.state.examsToTakeEasa.length; i++) {
                let examId = this.state.examsToTakeEasa[i].examId;
                let examName = this.state.examsToTakeEasa[i].examName;
                examsToTakeEasaData[examId] = examName;
            }

            let examsToTakeEasaShgmData = {};
            for (let i = 0; i < this.state.examsToTakeEasaShgm.length; i++) {
                let examId = this.state.examsToTakeEasaShgm[i].examId;
                let examName = this.state.examsToTakeEasaShgm[i].examName;
                examsToTakeEasaShgmData[examId] = examName;
            }

            let examsToTakeTrainingData = {};
            for (let i = 0; i < this.state.examsToTakeTraining.length; i++) {
                let examId = this.state.examsToTakeTraining[i].examId;
                let examName = this.state.examsToTakeTraining[i].examName;
                examsToTakeTrainingData[examId] = examName;
            }

            let isStudentInfoSaved = true;
            await database.ref('students/' + studentKey).set({
                name: this.state.name.toLowerCase(),
                surname: this.state.surname.toLowerCase(),
                email: this.state.email.toLowerCase(),
                phone: this.state.phone.toLowerCase(),
                birthdate: this.state.birthdate.toLowerCase(),
                birthplace: this.state.birthplace.toLowerCase(),
                notes: this.state.notes.toLowerCase(),
                passport: this.state.downloadURL,
                exams_to_take: {
                    module_easa: examsToTakeEasaData,
                    module_easa_shgm: examsToTakeEasaShgmData,
                    training: examsToTakeTrainingData
                }
            }, (error) => {
                if (error) {
                    isStudentInfoSaved = false;
                    this.showPopup("Couldn't add the student info!");
                    this.setState({ isLoading: false })
                }
            });
            if (isStudentInfoSaved) {
                this.showPopup('Student saved successfully.');
                this.setState({ isLoading: false })
                this.clearAllInput();
            }
        }
    };

    async onLoadExamInfo() {
        await database.ref('exams/').once('value').then((snapshot) => {
            let i = 0;
            let tempCategories = [];
            let tempModuleEasa = [];
            let tempModuleEasaShgm = [];
            let tempTrainings = [];

            snapshot.forEach((categorySnapshot) => {
                tempCategories.push(categorySnapshot.key.replace(/_/g, " "))
                categorySnapshot.forEach((examTypeSnaphot) => {
                    if (i === 0) {
                        tempModuleEasa.push({ examId: examTypeSnaphot.key, examName: examTypeSnaphot.val(), category: 'module_easa' })
                    }
                    else if (i === 1) {
                        tempModuleEasaShgm.push({ examId: examTypeSnaphot.key, examName: examTypeSnaphot.val(), category: 'module_easa_shgm' })
                    }
                    else if (i === 2) {
                        tempTrainings.push({ examId: examTypeSnaphot.key, examName: examTypeSnaphot.val(), category: 'training' })
                    }
                });
                i++;
            });
            tempModuleEasa.sort((a, b) => a.examName.localeCompare(b.examName) === 1 ? 1 : -1);
            tempModuleEasaShgm.sort((a, b) => a.examName.localeCompare(b.examName) === 1 ? 1 : -1);
            tempTrainings.sort((a, b) => a.examName.localeCompare(b.examName) === 1 ? 1 : -1);
            this.setState({ categories: tempCategories })
            this.setState({ moduleEasa: tempModuleEasa })
            this.setState({ moduleEasaShgm: tempModuleEasaShgm })
            this.setState({ trainings: tempTrainings })
            this.setState({ filteredExams: this.state.moduleEasa })
        }).catch((e) => {
            console.log(e)
        })
    };

    onEasaClick(event) {
        event.preventDefault();
        this.setState({ isEasaChecked: true })
        this.setState({ isEasaShgmChecked: false });
        this.setState({ isTrainingChecked: false });
        this.setState({ filteredExams: this.state.moduleEasa })
    }

    onEasaShgmClick(event) {
        event.preventDefault();
        this.setState({ isEasaShgmChecked: true })
        this.setState({ isEasaChecked: false });
        this.setState({ isTrainingChecked: false });
        this.setState({ filteredExams: this.state.moduleEasaShgm })
    }

    onTrainingClick(event) {
        event.preventDefault();
        this.setState({ isTrainingChecked: true })
        this.setState({ isEasaShgmChecked: false });
        this.setState({ isEasaChecked: false });
        this.setState({ filteredExams: this.state.trainings })
    }

    onChangeHandler = (event) => {
        const { name, value } = event.currentTarget;
        if (name === 'studentName') {
            this.setState({ name: value })
        }
        else if (name === 'studentSurname') {
            this.setState({ surname: value })
        }
        else if (name === 'studentEmail') {
            this.setState({ email: value })
        }
        else if (name === 'studentPhone') {
            this.setState({ phone: value })
        }
        else if (name === 'studentBirthdate') {
            this.setState({ birthdate: value })
        }
        else if (name === 'studentBirthplace') {
            this.setState({ birthplace: value })
        }
        else if (name === 'studentPassport') {
            this.setState({ passport: event.target.files[0] })
        }
        else if (name === 'studentNotes') {
            this.setState({ notes: value })
        }

    };

    componentDidMount() {
        auth.onAuthStateChanged((user) => {
            if (user) {
                this.setState({ isUserSignedIn: true });
            } else {
                this.setState({ isUserSignedIn: false });
            }
        });
    }

    onAdd = (category, examId, examName) => {
        if (category === 'module_easa') {
            this.state.examsToTakeEasa.push({ examId: examId, examName: examName, category: category });
            this.setState({ examsToTakeEasa: this.state.examsToTakeEasa });
            for (let i = 0; i < this.state.moduleEasa.length; i++) {
                if (this.state.moduleEasa[i].examId === examId) {
                    this.state.moduleEasa.splice(i, 1);
                    break;
                }
            }
        } else if (category === 'module_easa_shgm') {
            this.state.examsToTakeEasaShgm.push({ examId: examId, examName: examName, category: category });
            this.setState({ examsToTakeEasaShgm: this.state.examsToTakeEasaShgm });
            for (let i = 0; i < this.state.moduleEasaShgm.length; i++) {
                if (this.state.moduleEasaShgm[i].examId === examId) {
                    this.state.moduleEasaShgm.splice(i, 1);
                    break;
                }
            }
        } else if (category === 'training') {
            this.state.examsToTakeTraining.push({ examId: examId, examName: examName, category: category });
            this.setState({ examsToTakeTraining: this.state.examsToTakeTraining });
            for (let i = 0; i < this.state.trainings.length; i++) {
                if (this.state.trainings[i].examId === examId) {
                    this.state.trainings.splice(i, 1);
                    break;
                }
            }
        }
    }

    onRemove = (category, examId, examName) => {
        if (category === 'module_easa') {
            for (let i = 0; i < this.state.examsToTakeEasa.length; i++) {
                if (this.state.examsToTakeEasa[i].examId === examId) {
                    this.state.examsToTakeEasa.splice(i, 1);
                    break;
                }
            }
            this.state.moduleEasa.push({ examId: examId, examName: examName, category: category });
            this.state.examsToTakeEasa.sort((a, b) => a.examName.localeCompare(b.examName) === 1 ? 1 : -1)
            this.state.moduleEasa.sort((a, b) => a.examName.localeCompare(b.examName) === 1 ? 1 : -1)
            this.setState({ examsToTakeEasa: this.state.examsToTakeEasa });
        } else if (category === 'module_easa_shgm') {
            for (let i = 0; i < this.state.examsToTakeEasaShgm.length; i++) {
                if (this.state.examsToTakeEasaShgm[i].examId === examId) {
                    this.state.examsToTakeEasaShgm.splice(i, 1);
                    break;
                }
            }
            this.state.moduleEasaShgm.push({ examId: examId, examName: examName, category: category });
            this.state.examsToTakeEasaShgm.sort((a, b) => a.examName.localeCompare(b.examName) === 1 ? 1 : -1)
            this.state.moduleEasaShgm.sort((a, b) => a.examName.localeCompare(b.examName) === 1 ? 1 : -1)
            this.setState({ examsToTakeEasaShgm: this.state.examsToTakeEasaShgm });
        } else if (category === 'training') {
            for (let i = 0; i < this.state.examsToTakeTraining.length; i++) {
                if (this.state.examsToTakeTraining[i].examId === examId) {
                    this.state.examsToTakeTraining.splice(i, 1);
                    break;
                }
            }
            this.state.trainings.push({ examId: examId, examName: examName, category: category });
            this.state.examsToTakeTraining.sort((a, b) => a.examName.localeCompare(b.examName) === 1 ? 1 : -1)
            this.state.trainings.sort((a, b) => a.examName.localeCompare(b.examName) === 1 ? 1 : -1)
            this.setState({ examsToTakeTraining: this.state.examsToTakeTraining });
        }
    }

    render() {
        if (!this.state.isUserSignedIn) {
            return (
                <InvalidAuth></InvalidAuth>
            )
        }
        return (
            <div className="mb-3 break-words px-20">
                {/* Header */}
                <div className="h-1/6 flex font-sans text-3xl font-semibold tracking-wider text-left pt-8 text-pink-400">
                    <h2 className="w-3/6 pt-4">Create Student</h2>
                    <Link className="w-16 ml-auto" to="/home">
                        <img className="rounded-full" src={home} alt="pink" />
                    </Link>
                </div>

                {/* Elements */}
                <div className="my-4 font-sans placeholder-gray-200 bg-white border-2 border-pink-100">
                    <form className='overflow-y-auto p-10 grid grid-rows-2 grid-cols-4 gap-x-4 gap-y-2'>
                        <label className="block">
                            <span className="text-pink-400 text-xl">Name (*)</span>
                            <input
                                type="text"
                                name="studentName"
                                id="studentName"
                                className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 capitalize"
                                value={this.state.name}
                                onChange={(event) => this.onChangeHandler(event)}
                            />
                        </label>
                        <label className="block">
                            <span className="text-pink-400 text-xl">Surname (*)</span>
                            <input
                                type="text"
                                name="studentSurname"
                                id="studentSurname"
                                className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 capitalize"
                                value={this.state.surname}
                                onChange={(event) => this.onChangeHandler(event)}
                            />
                        </label>
                        <label className="block">
                            <span className="text-pink-400 text-xl">Email (*)</span>
                            <input
                                type="email"
                                name="studentEmail"
                                id="studentEmail"
                                className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 lowercase"
                                placeholder="xxxxxxx@example.com"
                                value={this.state.email}
                                onChange={(event) => this.onChangeHandler(event)}
                            />
                        </label>
                        <label className="block">
                            <span className="text-pink-400 text-xl">Phone (*)</span>
                            <PhoneInput
                                country={'tr'}
                                onlyCountries={['tr', 'gr', 'de', 'us', 'es', 'uk', 'fr']}
                                value={this.state.phone}
                                onChange={(phone) => this.setState({ phone })}
                            />

                        </label>
                        <label className="block">
                            <span className="text-pink-400 text-xl">Birthdate (*)</span>
                            <input
                                type="date"
                                className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 uppercase"
                                name="studentBirthdate"
                                id="studentBirthdate"
                                value={this.state.birthdate}
                                onChange={(event) => this.onChangeHandler(event)}
                            />
                        </label>
                        <label className="block">
                            <span className="text-pink-400 text-xl">Birthplace (*)</span>
                            <input
                                type="text"
                                name="studentBirthplace"
                                id="studentBirthplace"
                                className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 capitalize"
                                value={this.state.birthplace}
                                onChange={(event) => this.onChangeHandler(event)}
                            />
                        </label>
                        <label className="block">
                            <span className="text-pink-400 text-xl">Passport Image (*)</span>
                            <input
                                type="file"
                                key={this.state.passportKey}
                                className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 capitalize"
                                name="studentPassport"
                                id="studentPassport"
                                onChange={(event) => this.onChangeHandler(event)}
                            />
                        </label>
                        <label className="block row-span-2">
                            <span className="text-pink-400 text-xl">Additional Notes</span>
                            <textarea
                                className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 capitalize"
                                name="studentNotes"
                                id="studentNotes"
                                value={this.state.notes}
                                onChange={(event) => this.onChangeHandler(event)}
                            ></textarea>
                        </label>
                    </form>

                    <div className="w-full py-4 px-2 grid grid-cols-4 gap-x-1 gap-y-0 col-span-4">
                            <div className="w-full px-4 py-2 shadow-md col-span-1">
                                <div className="w-full inline-flex gap-x-2 px-1 py-2">
                                    <button className={!this.state.isEasaChecked ?
                                        "w-1/3 focus:outline-none rounded-full hover:bg-pink-300 border-2 text-sm"
                                        :
                                        "w-1/3 focus:outline-none rounded-full hover:bg-pink-300 border-2 bg-pink-500 text-sm"}
                                        onClick={(event) => { this.onEasaClick(event) }}>
                                        EASA
                                    </button>
                                    <button className={!this.state.isEasaShgmChecked ?
                                        "w-1/3 focus:outline-none rounded-full hover:bg-pink-300 border-2 text-sm"
                                        :
                                        "w-1/3 focus:outline-none rounded-full hover:bg-pink-300 border-2 bg-pink-500 text-sm"}
                                        onClick={(event) => { this.onEasaShgmClick(event) }}>
                                        EASA SHGM
                                    </button>
                                    <button className={!this.state.isTrainingChecked ?
                                        "w-1/3 focus:outline-none rounded-full hover:bg-pink-300 border-2 text-sm"
                                        :
                                        "w-1/3 focus:outline-none rounded-full hover:bg-pink-300 border-2 bg-pink-500 text-sm"}
                                        onClick={(event) => { this.onTrainingClick(event) }}>
                                        TRAINING
                                    </button>
                                </div>
                                <div className="overflow-y-auto">
                                    {this.state.filteredExams
                                        .map((exam, index) =>
                                            <ExamToTake
                                                key={exam.examId}
                                                exam={exam}
                                                index={index}
                                                isAdd={true}
                                                onAdd={this.onAdd}
                                                onRemove={this.onRemove}
                                            />)
                                    }
                                </div>
                            </div>
                            <div className="w-full px-4 py-2 shadow-md col-span-1">
                                <h3 className="w-full pb-4 uppercase underline font-semibold text-pink-500">EASA</h3>
                                <div className="overflow-y-auto">
                                    {this.state.examsToTakeEasa
                                        .map((exam, index) =>
                                            <ExamToTake
                                                key={exam.examId}
                                                exam={exam}
                                                index={index}
                                                isAdd={false}
                                                onAdd={this.onAdd}
                                                onRemove={this.onRemove}
                                            />)
                                    }
                                </div>
                            </div>
                            <div className="w-full px-4 py-2 shadow-md col-span-1">
                                <h3 className="w-full pb-4 uppercase underline font-semibold text-pink-500">EASA SHGM</h3>
                                <div className="overflow-y-auto">
                                    {this.state.examsToTakeEasaShgm
                                        .map((exam, index) =>
                                            <ExamToTake
                                                key={exam.examId}
                                                exam={exam}
                                                index={index}
                                                isAdd={false}
                                                onAdd={this.onAdd}
                                                onRemove={this.onRemove}
                                            />)
                                    }
                                </div>
                            </div>
                            <div className="w-full px-4 py-2 shadow-md col-span-1">
                                <h3 className="w-full pb-4 uppercase underline font-semibold text-pink-500">TRAINING</h3>
                                <div className="overflow-y-auto">
                                    {this.state.examsToTakeTraining
                                        .map((exam, index) =>
                                            <ExamToTake
                                                key={exam.examId}
                                                exam={exam}
                                                index={index}
                                                isAdd={false}
                                                onAdd={this.onAdd}
                                                onRemove={this.onRemove}
                                            />)
                                    }
                                </div>
                            </div>
                            <input
                                type="submit"
                                value="Add Student"
                                name="studentSubmit"
                                id="studentSubmit"
                                className="mt-5 col-span-4 mx-auto h-12 block w-1/5 rounded-2xl bg-pink-400 hover:bg-pink-300 text-white outline-none capitalize"
                                onClick={(event) => {
                                    this.handleCheck(event)
                                }}
                            />
                        </div>
                </div>
                <BounceLoader
                    color='#f472b6'
                    loading={this.state.isLoading}
                    size={150}
                    css={css`
                            display: block;
                            margin: 0 auto;
                            border-color: red;
                            position: absolute;
                            top: calc(50% - 75px);
                            left: calc(50% - 75px);
                            `}
                />
            </div>
        )
    }
}

export default CreateStudent