import React, { Component } from "react";
import { Link } from "react-router-dom";
import Moment from 'react-moment'
import { database, auth } from "../../Database/Firebase";
import home from "../../Styles/Home/home-icon-black.png";
import back from "../../Styles/Icon/icon-back.png";
import ExamsListAssignStudent from './ExamsListAssignStudent'
import ExamsListEditStudent from "./ExamsListEditStudent";
import InvalidAuth from "../Authentication/InvalidAuth"

class ExamsListAssign extends Component {

    constructor() {
        super()
        this.state = {
            allStudents: [],
            selectedStudents: [],
            examTakers: [],
            selectedTakers: [],
            isUserSignedIn: false,
            searchStringforAll: '',
            searchStringforTakers: ''
        }
    }

    async fetchAllStudents() {
        let examPropsValue = this.props.location.state.examProps.value;
        var studentListRef = database.ref('/students').orderByChild('name');
        await studentListRef.on('value', (snapshot) => {
            let studentList = [];
            snapshot.forEach((childSnapshot) => {
                let child = childSnapshot.val();
                let exists = false;
                for (let index = 0; index < this.state.examTakers.length; index++) {
                    const taker = this.state.examTakers[index];
                    if (taker.key === childSnapshot.key) {
                        exists = true;
                        break;
                    }
                }
                if (exists === false) {
                    if (child.taken_exams) {
                        let passed = false;
                        for (const [key, takenExam] of Object.entries(child.taken_exams)) {
                            if (examPropsValue.category === takenExam.category &&
                                examPropsValue.type === takenExam.type &&
                                takenExam.status === 'passed') {
                                passed = true;
                                break;
                            }
                        }
                        if(!passed) {
                            studentList.push({ 'key': childSnapshot.key, 'value': child });
                        }
                    } else {
                        studentList.push({ 'key': childSnapshot.key, 'value': child });
                    }
                }
            })
            this.setState({ allStudents: studentList });
        });

    }

    async fetchExamTakers() {
        const key = this.props.location.state.examProps.key;
        const examRef = database.ref(`exam_dates/${key}/exam_takers`);
        await examRef.on("value", (snapshot) => {
            let takers = [];
            snapshot.forEach((childSnapshot) => {
                for (let index = 0; index < this.state.allStudents.length; index++) {
                    const student = this.state.allStudents[index];
                    if (student.key === childSnapshot.key) {
                        this.state.allStudents.splice(index, 1);
                    }
                }
                takers.push({
                    'key': childSnapshot.key,
                    'name': childSnapshot.val().name,
                    'surname': childSnapshot.val().surname,
                    'status': childSnapshot.val().status,
                    'certificated': childSnapshot.val().certificated
                });
            })
            this.setState({ examTakers: takers })
        })
        this.fetchAllStudents();
    }

    componentDidMount() {
        this.fetchExamTakers();
        auth.onAuthStateChanged((user) => {
            if (user) {
                this.setState({ isUserSignedIn: true });
            } else {
                this.setState({ isUserSignedIn: false });
            }
        });
    }

    handleChangeForAll = event => {
        const { value } = event.target;
        this.setState({ searchStringforAll: value });
    };

    handleChangeForTakers = event => {
        const { value } = event.target;
        this.setState({ searchStringforTakers: value });
    };

    render() {
        if (!this.state.isUserSignedIn) {
            return (
                <InvalidAuth></InvalidAuth>
            )
        }
        const exam = this.props.location.state.examProps;
        return (
            <div className='h-screen break-words px-20 pb-8'>
                {/* HEADER  */}
                <div className="h-1/5 flex font-sans text-2xl font-semibold tracking-wider text-left pt-8 text-gray-900 border-b-2 border-gray-500">
                    <h2 className="w-3/4 pt-4 uppercase">{exam.value.category.replace(/_/g, " ")} - {exam.value.type} - <Moment format="DD.MM.YYYY">{exam.value.date}</Moment></h2>
                    <div className="flex block ml-auto">
                        <Link to="/exams">
                            <img className="w-14 mx-8 rounded-full border-2 border-gray-500" src={back} alt="back" />
                        </Link>
                        <Link className="w-14" to="/home">
                            <img className="rounded-full" src={home} alt="yellow" />
                        </Link>
                    </div>
                </div>
                {/* BODY */}
                <div className="grid grid-cols-2 gap-x-2 h-3/5">
                    <div className="mt-10 px-6 overflow-y-auto border-r-2 border-gray-200 text-black">
                        <div className="flex w-full mb-4">
                            <h3 className="w-1/2 font-semibold uppercase underline text-blue-800 text-xl">All Students</h3>
                            <div className="w-1/2 h-1/2 ml-auto border-b-2 border-l-0 border-r-0 border-t-0 flex text-gray-600" >
                                <input className="border-none w-5/6 focus:ring-0"
                                    type="text" value={this.state.searchStringForAll} onChange={this.handleChangeForAll} />
                                <p className="border-none w-6 h-6 ml-auto bg-search bg-contain bg-no-repeat"></p>
                            </div>
                        </div>
                        {this.state.allStudents.sort(function (a, b) {
                            if (a.value.name < b.value.name) { return -1; }
                            if (a.value.name > b.value.name) { return 1; }
                            return 0;
                        }).map((student, index) => {
                            if ((student.value.name + student.value.surname).includes(this.state.searchStringforAll.toLowerCase())) {
                                return <ExamsListAssignStudent key={student.key} student={student} exam={exam} index={index}
                                    studentKey={student.key}
                                />
                            } else {
                                return null;
                            }
                        })}
                    </div>
                    <div className="mt-10 px-6 overflow-y-auto text-black">
                        <div className="flex w-full mb-4">
                            <h3 className="w-1/2 font-semibold uppercase underline text-blue-800 text-xl">Added Students - ({this.state.examTakers.length})</h3>
                            <div className="w-1/2 h-1/2 ml-auto border-b-2 border-l-0 border-r-0 border-t-0 flex text-gray-600" >
                                <input className="border-none w-5/6 focus:ring-0"
                                    type="text" value={this.state.searchStringforTakers} onChange={this.handleChangeForTakers} />
                                <p className="border-none w-6 h-6 ml-auto bg-search bg-contain bg-no-repeat"></p>
                            </div>
                        </div>
                        {this.state.examTakers.sort(function (a, b) {
                            if (a.name < b.name) { return -1; }
                            if (a.name > b.name) { return 1; }
                            return 0;
                        }).map((student, index) => {
                            if ((student.name + student.surname).includes(this.state.searchStringforTakers.toLowerCase())) {
                                return <ExamsListEditStudent key={student.key}
                                    taker={student} exam={exam} index={index} studentKey={student.key}
                                />
                            } else {
                                return null;
                            }
                        })}
                    </div>
                </div>
            </div>
        )
    }

}

export default ExamsListAssign