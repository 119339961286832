import React, { Component } from "react";
import { database } from "../../Database/Firebase";

class ExamsListAssignStudent extends Component {

    async onAdd(event) {
        event.preventDefault();
        const exam = this.props.exam;
        const examRef = database.ref(`exam_dates/${exam.key}/exam_takers`);

        const student = this.props.student;
        const studentKey = this.props.studentKey;

        let tempStudent = {
            name: student.value.name,
            surname: student.value.surname,
            status: 'none',
            certificated: false
        }

        await examRef.child(studentKey).update(tempStudent);

        const takenExamKey = exam.key;
        const takenExamRef = database.ref(`students/${studentKey}/taken_exams/${takenExamKey}`)

        let examInfo = {
            key: exam.key,
            category: exam.value.category,
            city: exam.value.city,
            date: exam.value.date,
            type: exam.value.type,
            status: 'none',
            certificateGiven: false
        }
        await takenExamRef.update(examInfo);
    }

    render() {
        return (
            <div className="w-full inline-flex grid grid-cols-5 gap-x-4 px-4 py-2 mb-2 shadow-md capitalize">
                <label className="col-span-4">
                    {this.props.student.value.name} {this.props.student.value.surname}
                </label>
                <button className="col-span-1 focus:outline-none rounded-full bg-contain bg-center bg-no-repeat bg-plus bg-lime-500 hover:bg-lime-600"
                    onClick={(event) => { this.onAdd(event) }}>
                </button>
            </div>
        )
    }
}

export default ExamsListAssignStudent