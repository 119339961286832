import React, { Component } from "react";
import { Link } from "react-router-dom";
import { database, auth } from "../../Database/Firebase";
import home from "../../Styles/Home/home-icon-blue.png";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'
import { css } from "@emotion/core";
import BounceLoader from "react-spinners/BounceLoader";
import InvalidAuth from "../Authentication/InvalidAuth"

class CreateExamDate extends Component {

    constructor() {
        super()
        this.state = {
            moduleEasa: [],
            moduleEasaSghm: [],
            trainings: [],
            categories: [],
            cities: [],
            selectedCity: '',
            selectedCategory: '',
            selectedType: '',
            examDate: '',
            isLoading: false,
            isUserSignedIn: false
        }
        this.onLoadExamInfo();
        this.onLoadCity();
    }

    isEmpty(str) {
        return (!str || 0 === str.length);
    }

    showPopup(popupMessage) {
        confirmAlert({
            title: 'CREATE EXAM DATE',
            message: popupMessage,
            customUI: ({ title, message, onClose }) =>
                <div className="w-80 px-10 py-8 bg-white font-family:Arial text-gray-600 text-center border rounded border-gray-600">
                    <h3 className="w-full mb-1">{title}</h3>
                    <h4 className="w-full mb-3">{message}</h4>
                    <button
                        className="w-5/6 rounded text-white bg-lime-400 hover:bg-lime-600 focus:outline-none"
                        onClick={() => { onClose() }}
                    >
                        OK</button>
                </div>
        })
    };

    clearAllInput() {
        this.setState({
            cities: [],
            selectedCity: '',
            selectedCategory: '',
            selectedType: '',
            examDate: ''
        });
    }

    async onSubmit(event) {
        event.preventDefault();

        if (this.isEmpty(this.state.selectedCategory) || this.isEmpty(this.state.selectedType) ||
            this.isEmpty(this.state.selectedCity) || this.isEmpty(this.state.examDate)) {
            this.showPopup("All the required fields must be filled (*)");
            return;
        }

        this.setState({ isLoading: true })

        let examDatesRef = database.ref('exam_dates');
        let examDatesKey = examDatesRef.push().key;

        await database.ref('exam_dates/' + examDatesKey).set({
            category: this.state.selectedCategory,
            type: this.state.selectedType,
            date: this.state.examDate,
            city: this.state.selectedCity
        }, (error) => {
            if (error) {
                this.showPopup("Couldn't create exam date!");
                this.setState({ isLoading: false })
            } else {
                this.showPopup('Exam date created successfully.');
                this.setState({ isLoading: false })
                this.clearAllInput();
            }
        });

    };

    async onLoadExamInfo() {
        await database.ref('exams/').once('value').then((snapshot) => {
            let i = 0;
            let tempCategories = [];
            let tempModuleEasa = [];
            let tempModuleEasaSghm = [];
            let tempTrainings = [];

            snapshot.forEach((categorySnapshot) => {
                tempCategories.push(categorySnapshot.key.replace(/_/g, " "))
                categorySnapshot.forEach((examTypeSnaphot) => {
                    if (i === 0) {
                        tempModuleEasa.push(examTypeSnaphot.val())
                    }
                    else if (i === 1) {
                        tempModuleEasaSghm.push(examTypeSnaphot.val())
                    }
                    else if (i === 2) {
                        tempTrainings.push(examTypeSnaphot.val())
                    }
                });
                i++;
            });
            this.setState({ categories: tempCategories })
            this.setState({ moduleEasa: tempModuleEasa })
            this.setState({ moduleEasaSghm: tempModuleEasaSghm })
            this.setState({ trainings: tempTrainings })
        }).catch((e) => {
            console.log(e)
        })
    };

    async onLoadCity() {
        await database.ref('cities/exam_cities/').once('value').then((snapshot) => {
            let temp = [];
            snapshot.forEach((citySnapshot) => {
                temp.push(citySnapshot.val())
            });
            this.setState({ cities: temp })
        }).catch((e) => {
            console.log(e)
        })
    }

    onSelectCityHandler(event) {
        const element = event.target;
        let text = element.options[element.selectedIndex].text;
        this.setState({ selectedCity: text });
    }

    onSelectExamCategoryHandler(event) {
        const selectElement = event.target;
        let text = selectElement.options[selectElement.selectedIndex].text.replace(/ /g, "_");
        this.setState({ selectedCategory: text });
    }

    onSelectExamTypeHandler(event) {
        const selectElement = event.target;
        let text = selectElement.options[selectElement.selectedIndex].text;
        this.setState({ selectedType: text });
    }

    onSelectDateHandler(event) {
        const { value } = event.currentTarget;
        this.setState({ examDate: value })
    }

    componentDidMount() {
        auth.onAuthStateChanged((user) => {
            if (user) {
                this.setState({ isUserSignedIn: true });
            } else {
                this.setState({ isUserSignedIn: false });
            }
        });
    }

    render() {
        if (!this.state.isUserSignedIn) {
            return (
                <InvalidAuth></InvalidAuth>
            )
        }
        return (
            <div className="h-screen break-words px-20 pb-8">
                {/* Header */}
                <div className="h-1/6 flex font-sans text-3xl font-semibold tracking-wider text-left pt-8 text-blue-400">
                    <h2 className="w-3/6 pt-4">Exam Date Creator</h2>
                    <Link className="w-16 ml-auto" to="/home">
                        <img className="rounded-full" src={home} alt="blue" />
                    </Link>
                </div>

                {/* Elements */}
                <div className="h-5/6 font-sans placeholder-gray-200 bg-white">
                    <form className='h-full border-2 p-10 grid grid-rows-5 grid-cols-2 gap-x-4 gap-y-32 border-blue-100'>
                        <label className="block">
                            <span className="text-blue-400 text-xl">Category (*)</span>
                            <select
                                name="exam"
                                id="exam"
                                onChange={(event) => this.onSelectExamCategoryHandler(event)}
                                className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 uppercase">
                                <option>Please Select Exam Category</option>
                                {this.state.categories.sort()
                                    .map((exam, index) => {
                                        return (
                                            <option key={index}> {exam} </option>
                                        )
                                    })
                                }
                            </select>
                        </label>
                        <label className="block">
                            <span className="text-blue-400 text-xl">Type (*)</span>
                            <select
                                name="examType"
                                id="examType"
                                onChange={(event) => this.onSelectExamTypeHandler(event)}
                                className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 uppercase">
                                <option>Please Select Exam Type</option>
                                {
                                    this.state.moduleEasa.sort()
                                        .map((easa, index) => {
                                            if (this.state.selectedCategory === 'module_easa') {
                                                return (
                                                    <option key={index}> {easa} </option>
                                                )
                                            } else {
                                                return null;
                                            }
                                        })
                                }
                                {
                                    this.state.moduleEasaSghm.sort()
                                        .map((sghm, index) => {
                                            if (this.state.selectedCategory === 'module_easa_sghm') {
                                                return (
                                                    <option key={index}> {sghm} </option>
                                                )
                                            } else {
                                                return null;
                                            }
                                        })
                                }
                                {
                                    this.state.trainings.sort()
                                        .map((training, index) => {
                                            if (this.state.selectedCategory === 'training') {
                                                return (
                                                    <option key={index}> {training} </option>
                                                )
                                            } else {
                                                return null;
                                            }
                                        })
                                }

                            </select>
                        </label>
                        <label>
                            <span className="text-blue-400 text-xl">City (*)</span>
                            <select
                                name="examCity"
                                id="examCity"
                                onChange={(event) => this.onSelectCityHandler(event)}
                                className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 uppercase">
                                <option>Please Select City</option>
                                {
                                    this.state.cities.sort()
                                        .map((city, index) => {
                                            return (
                                                <option key={index}> {city} </option>
                                            )
                                        })
                                }
                            </select>
                        </label>
                        <label className="block">
                            <span className="text-blue-400 text-xl">Exam Date (*)</span>
                            <input
                                type="date"
                                name="examDate"
                                id="examDate"
                                value={this.state.examDate}
                                className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 uppercase"
                                onChange={(event) => this.onSelectDateHandler(event)}
                            />
                        </label>
                        <input
                            type="submit"
                            value="Create Exam"
                            className="mt-6 mx-auto h-12 block w-3/5 rounded-2xl bg-blue-300 hover:bg-blue-400 text-white outline-none uppercase"
                            name="createExam"
                            id="createExam"
                            onClick={(event) => {
                                this.onSubmit(event)
                            }}
                        />
                    </form>
                </div>
                <BounceLoader
                    color='#3B82F6'
                    loading={this.state.isLoading}
                    size={150}
                    css={css`
                            display: block;
                            margin: 0 auto;
                            border-color: red;
                            position: absolute;
                            top: calc(50% - 75px);
                            left: calc(50% - 75px);
                            `}
                />
            </div >
        )
    }
}

export default CreateExamDate