import React, { Component } from "react";
import { auth } from "../../Database/Firebase";
import { Link } from "react-router-dom";
import Moment from 'react-moment';
import InvalidAuth from "../Authentication/InvalidAuth"

class ResultsList extends Component {

    constructor() {
        super();
        this.state = {
            isUserSignedIn: false
        }
    }

    componentDidMount() {
        auth.onAuthStateChanged((user) => {
            if (user) {
                this.setState({ isUserSignedIn: true });
            } else {
                this.setState({ isUserSignedIn: false });
            }
        });
    }

    render() {
        if (!this.state.isUserSignedIn) {
            return (
                <InvalidAuth></InvalidAuth>
            )
        }
        const value = this.props.exam.value;
        return (
            <div className="mt-5 h-36 w-full rounded-2xl border-2 border-rose-200 shadow-lg">
                <div className="block py-2 h-24 w-full rounded-t-xl bg-rose-300 text-white text-left text-xl font-semibold">
                    <div className="px-4 capitalize">
                        <label className="text-bold text-rose-50">{value.category.replace(/_/g, " ")}<br /> {value.type.substring(0, 15)}<br />
                            <Moment format="YYYY-MM-DD">{value.date}</Moment></label>
                    </div>
                </div>
                <Link
                    to={{
                        pathname: `results/add/${this.props.exam.key}`,
                        state: {
                            exam: this.props.exam
                        }
                    }}>
                    <div className="h-11 w-full py-2 text-center text-rose-800 rounded-b-2xl border-gray-200 hover:bg-gray-200 bg-contain bg-center bg-no-repeat bg-result"></div>
                </Link>
            </div >
        )
    }
}

export default ResultsList