import React, { Component } from "react";
import { Link } from "react-router-dom";
import { database, auth } from "../../Database/Firebase";
import home from "../../Styles/Home/home-icon-black.png";
import back from "../../Styles/Icon/icon-back.png";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'
import InvalidAuth from "../Authentication/InvalidAuth"
import BounceLoader from "react-spinners/BounceLoader";
import { css } from "@emotion/core";


class ExamsListEditExam extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isUserSignedIn: false,
            isLoading: false,
            type: this.props.location.state.exam.value.type,
            category: this.props.location.state.exam.value.category,
            city: this.props.location.state.exam.value.city,
            examDate: this.props.location.state.exam.value.date,
            moduleEasa: [],
            moduleEasaSghm: [],
            trainings: [],
            categories: [],
            cities: []
        }
        this.onLoadExamInfo();
        this.onLoadCity();
        //this.onLoadStudents();
    }

    isEmpty(str) {
        return (!str || 0 === str.length);
    }

    componentDidMount() {
        auth.onAuthStateChanged((user) => {
            if (user) {
                this.setState({ isUserSignedIn: true });
            } else {
                this.setState({ isUserSignedIn: false });
            }
        });
    }

    showEditPopup(category, type, date) {
        confirmAlert({
            title: 'EDIT EXAM',
            message: 'Changes saved for:',
            customUI: ({ title, message, onClose }) =>
                <div className="w-80 px-10 py-8 bg-white font-family:Arial text-gray-600 text-center border rounded border-gray-600">
                    <h3 className="w-full mb-1">{title}</h3>
                    <h4 className="w-full mb-3">
                        {message}<br />
                        {category.toUpperCase()}<br />
                        {type.toUpperCase()}<br />
                        {date.toUpperCase()}
                    </h4>
                    <div>
                        <button className="w-full rounded text-white bg-lime-400 hover:bg-lime-600 focus:outline-none"
                            onClick={() => { onClose(); }}>
                            OK
                        </button>
                    </div>
                </div>
        });
    };

    showConfirmPopup(event, category, type, date, city) {
        event.preventDefault();
        if (this.isEmpty(category)
            || this.isEmpty(type)
            || this.isEmpty(date)
            || this.isEmpty(city)) {
            this.showPopup("All the required fields must be filled (*)");
            return;
        }
        confirmAlert({
            title: 'EDIT EXAM',
            message: 'Are you sure to update:',
            customUI: ({ title, message, onClose }) =>
                <div className="w-80 px-10 py-8 bg-white font-family:Arial text-gray-600 text-center border rounded border-gray-600">
                    <h3 className="w-full mb-1">{title}</h3>
                    <h4 className="w-full mb-3">
                        {message}<br />
                        {category.toUpperCase()}<br />
                        {type.toUpperCase()}<br />
                        {date.toUpperCase()}<br />
                        {city.toUpperCase()}
                    </h4>
                    <div className="grid grid-cols-2 gap-x-2">
                        <button className="w-full rounded text-white bg-lime-400 hover:bg-lime-600 focus:outline-none"
                            onClick={(event) => { this.onUpdateExam(); onClose(); }}>
                            YES
                        </button>
                        <button className="w-full rounded text-white bg-red-400 hover:bg-red-600 focus:outline-none"
                            onClick={(event) => { onClose(); }}>
                            NO
                        </button>
                    </div>
                </div>
        });
    };

    showPopup(popupMessage) {
        confirmAlert({
            title: 'UPDATE EXAM',
            message: popupMessage,
            customUI: ({ title, message, onClose }) =>
                < div className="w-80 px-10 py-8 bg-white font-family:Arial text-gray-600 text-center border rounded border-gray-600" >
                    <h3 className="w-full mb-1">{title}</h3>
                    <h4 className="w-full mb-3">{message}</h4>
                    <button
                        className="w-5/6 rounded text-white bg-lime-400 hover:bg-lime-600 focus:outline-none"
                        onClick={() => { onClose() }}>OK</button>
                </div >
        })
    };

    onSelectCityHandler(event) {
        const element = event.target;
        let text = element.options[element.selectedIndex].text;
        this.setState({ city: text });
    }

    onSelectExamCategoryHandler(event) {
        const selectElement = event.target;
        let text = selectElement.options[selectElement.selectedIndex].text.replace(/ /g, "_");
        this.setState({ category: text });
        this.setState({ type: '' });
    }

    onSelectExamTypeHandler(event) {
        const selectElement = event.target;
        let text = selectElement.options[selectElement.selectedIndex].text;
        this.setState({ type: text });
    }

    onSelectDateHandler(event) {
        const { value } = event.currentTarget;
        this.setState({ examDate: value })
    }

    async onLoadExamInfo() {
        await database.ref('exams/').once('value').then((snapshot) => {
            let i = 0;
            let tempCategories = [];
            let tempModuleEasa = [];
            let tempModuleEasaSghm = [];
            let tempTrainings = [];

            snapshot.forEach((categorySnapshot) => {
                tempCategories.push(categorySnapshot.key.replace(/_/g, " "))
                categorySnapshot.forEach((examTypeSnaphot) => {
                    if (i === 0) {
                        tempModuleEasa.push(examTypeSnaphot.val())
                    }
                    else if (i === 1) {
                        tempModuleEasaSghm.push(examTypeSnaphot.val())
                    }
                    else if (i === 2) {
                        tempTrainings.push(examTypeSnaphot.val())
                    }
                });
                i++;
            });
            this.setState({ categories: tempCategories })
            this.setState({ moduleEasa: tempModuleEasa })
            this.setState({ moduleEasaSghm: tempModuleEasaSghm })
            this.setState({ trainings: tempTrainings })
        }).catch((e) => {
            console.log(e)
        })
    };

    async onLoadCity() {
        await database.ref('cities/exam_cities/').once('value').then((snapshot) => {
            let temp = [];
            snapshot.forEach((citySnapshot) => {
                temp.push(citySnapshot.val())
            });
            this.setState({ cities: temp })
        }).catch((e) => {
            console.log(e)
        })
    };

    async onUpdateExam() {

        const exam = this.props.location.state.exam;

        this.setState({ isLoading: true })

        let isExamInfoSaved = true;
        await database.ref('exam_dates/' + exam.key).update({
            category: this.state.category,
            type: this.state.type,
            date: this.state.examDate,
            city: this.state.city,
        }, (error) => {
            if (error) {
                console.log(error);
                isExamInfoSaved = false;
                this.setState({ isLoading: false })
            } else {
                this.showEditPopup(
                    this.state.category.toLowerCase(),
                    this.state.type.toLowerCase(),
                    this.state.examDate);
            }
        });

        const students = exam.exam_takers;
        await students.forEach((student) => {
            let ref = database.ref('students/' + student.key + '/taken_exams/' + exam.key);
            ref.once("value").then((snapshot) => {
                if (snapshot.exists()) {
                    ref.update({
                        category: this.state.category.toLowerCase(),
                        type: this.state.type.toLowerCase(),
                        date: this.state.examDate,
                        city: this.state.city.toLowerCase()
                    }, (error) => {
                        if (error) {
                            console.log(error);
                        }
                    });
                }
            });

        });
        if (isExamInfoSaved) {
            this.setState({ isLoading: false })
        }
        this.props.history.push("/exams");
    }

    render() {
        if (!this.state.isUserSignedIn) {
            return (
                <InvalidAuth></InvalidAuth>
            )
        }
        return (
            <div className="h-screen w-full break-words px-20 pb-8">
                {/* HEADER */}
                <div className="h-1/6 mb-4 flex font-sans text-2xl font-semibold tracking-wider text-left pt-8 text-gray-400 border-b-2 border-gray-300 capitalize">
                    <h2 className="w-full pt-4">{this.state.category.replace(/_/g, " ")} - {this.state.type.substring(0, 50)} - {this.state.examDate}</h2>
                    <Link to="/exams">
                        <img className="rounded-full ml-10 w-14 border-2 border-gray-500" src={back} alt="back" />
                    </Link>
                    <Link to="/home" className="ml-10">
                        <img className="rounded-full w-16 ml-auto" src={home} alt="home" />
                    </Link>
                </div>
                <div className="h-5/6 font-sans placeholder-gray-200 bg-white">
                    <form className='h-full border-2 p-10 grid grid-rows-5 grid-cols-2 gap-x-4 gap-y-32 border-gray-100'>
                        <label className="block">
                            <span className="text-gray-400 text-xl">Category (*)</span>
                            <select
                                value={this.state.category}
                                onChange={(event) => this.onSelectExamCategoryHandler(event)}
                                className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 uppercase">
                                <option>{this.state.category.replace(/_/g, " ")}</option>
                                {this.state.categories.sort()
                                    .map((exam, index) => {
                                        return (
                                            <option key={index}> {exam} </option>
                                        )
                                    })
                                }
                            </select>
                        </label>
                        <label className="block">
                            <span className="text-gray-400 text-xl">Type (*)</span>
                            <select
                                onChange={(event) => this.onSelectExamTypeHandler(event)}
                                className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 uppercase">
                                <option>{this.state.type}</option>
                                {
                                    this.state.moduleEasa.sort()
                                        .map((easa, index) => {
                                            if (this.state.category === 'module_easa') {
                                                return (
                                                    <option key={index}> {easa} </option>
                                                )
                                            } else {
                                                return null;
                                            }
                                        })
                                }
                                {
                                    this.state.moduleEasaSghm.sort()
                                        .map((sghm, index) => {
                                            if (this.state.category === 'module_easa_sghm') {
                                                return (
                                                    <option key={index}> {sghm} </option>
                                                )
                                            } else {
                                                return null;
                                            }
                                        })
                                }
                                {
                                    this.state.trainings.sort()
                                        .map((training, index) => {
                                            if (this.state.category === 'training') {
                                                return (
                                                    <option key={index}> {training} </option>
                                                )
                                            } else {
                                                return null;
                                            }
                                        })
                                }
                            </select>
                        </label>
                        <label>
                            <span className="text-gray-400 text-xl">City (*)</span>
                            <select
                                value={this.state.city}
                                onChange={(event) => this.onSelectCityHandler(event)}
                                className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 uppercase">
                                {
                                    this.state.cities.sort()
                                        .map((city, index) => {
                                            return (
                                                <option key={index}> {city} </option>
                                            )
                                        })
                                }
                            </select>
                        </label>
                        <label className="block">
                            <span className="text-gray-400 text-xl">Exam Date (*)</span>
                            <input
                                type="date"
                                value={this.state.examDate}
                                className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 uppercase"
                                onChange={(event) => this.onSelectDateHandler(event)}
                            />
                        </label>
                        <button
                            type="submit"
                            value="Update Exam"
                            className="mt-6 mx-auto h-12 block w-3/5 rounded-2xl bg-gray-300 hover:bg-gray-400 text-white focus:outline-none uppercase"
                            onClick={(event) => {
                                this.showConfirmPopup(event,
                                    this.state.category,
                                    this.state.type,
                                    this.state.examDate,
                                    this.state.city)
                            }}
                        > Update Exam</button>
                    </form>
                </div>

                <BounceLoader
                    color='#FBBF24'
                    loading={this.state.isLoading}
                    size={150}
                    css={css`
                            display: block;
                            margin: 0 auto;
                            border-color: red;
                            position: absolute;
                            top: calc(50% - 75px);
                            left: calc(50% - 75px);
                            `}
                />
            </div>
        )
    }
}

export default ExamsListEditExam