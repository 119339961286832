import React, { Component } from "react";
import { Link } from "react-router-dom";
import { auth } from "../Database/Firebase";
import InvalidAuth from "./Authentication/InvalidAuth"
import home from "../Styles/logo.png";
import filter from "../Styles/Icon/filter.png";

class Home extends Component {

  constructor() {
    super();
    this.state = {
      students: [],
      isUserSignedIn: false
    }
  }

  logOut(event) {
    auth.onAuthStateChanged(function (user) {
      if (user) {
      } else {
        console.log('signed out')
      }
    });
    event.preventDefault();
    auth.signOut().then((result) => {
      // Sign-out successful.
      console.log(result);
      this.props.history.push("/");
    }).catch((error) => {
      // An error happened.
      console.log(error.code + error.message)
    });
  }

  componentDidMount() {
    auth.onAuthStateChanged((user) => {
      if (user) {
        this.setState({ isUserSignedIn: true });
      } else {
        this.setState({ isUserSignedIn: false });
      }
    });
  }

  render() {
    if (!this.state.isUserSignedIn) {
      return (
        <InvalidAuth></InvalidAuth>
      )
    }
    return (
      <div className="h-screen w-screen min-h-screen">
        {/* Header */}
        {/*<button onClick={(event) => { this.fetch(event); }}>Fetch Database</button>
        <button onClick={(event) => { this.update(event); }}>Update Database</button>*/}
        <div className="h-1/12 w-screen flex">
          <Link to="/home" className="h-full">
            <img className="w-24 ml-20 flex-shrink float-left" src={home} alt="HMC" />
          </Link>
          <div className="ml-auto float-right flex">
            <Link to="/filter" className="max-w-10 max-h-10 mt-6 mr-10">
              <img className="bg-magenta hover:bg-gold w-10 flex-shrink p-2 rounded-2xl" src={filter} alt="Filter" />
            </Link>
            <button
              className="bg-magenta hover:bg-gold w-24 text-white rounded-2xl text-xl ml-auto mr-20 float-right max-h-12 mt-5 focus:outline-none"
              onClick={(event) => {
                this.logOut(event);
              }}
            >
              Log Out
            </button>
          </div>
        </div>

        {/* Grid Button View  */}
        <div className="h-4/5 grid grid-rows-2 grid-cols-3 gap-8 py-10 mx-20 content-center place-content-evenly">
          <Link
            className="bg-pinky hover:bg-gray-300 w-full py-5 text-white rounded-2xl text-xl text-center py-24 "
            to="/create_student"
          >
            Create Student
          </Link>
          <Link
            className="bg-blue-400 hover:bg-gray-300 w-full py-5 text-white rounded-2xl text-xl text-center py-24"
            to="/create_exam_date"
          >
            Create Exam Dates
          </Link>
          <Link
            className="bg-lime hover:bg-gray-300 w-full py-5 text-white rounded-2xl text-xl text-center py-24"
            to="/certificated"
          >
            Certificated Students
          </Link>
          <Link
            className="bg-lila hover:bg-gray-300 w-full py-5 text-white rounded-2xl text-xl text-center py-24"
            to="/students"
          >
            Students
          </Link>
          <Link
            className="bg-gold hover:bg-gray-300 w-full py-5 text-white rounded-2xl text-xl text-center py-24"
            to="/exams"
          >
            Exams
          </Link>
          <Link
            className="bg-rose-400 hover:bg-gray-300 w-full py-5 text-white rounded-2xl text-xl text-center py-24"
            to="/results"
          >
            Add Result
          </Link>
        </div>
      </div>
    );
  }

}

export default Home;