import React, { Component } from "react";
import { Link } from "react-router-dom";
import Moment from 'react-moment'
import { database, auth } from "../../Database/Firebase";
import home from "../../Styles/Home/home-icon-black.png";
import back from "../../Styles/Icon/icon-back.png";
import ResultsListAddResultFailed from "./ResultsListAddResultFailed";
import ResultsListAddResultPassed from "./ResultsListAddResultPassed";
import ResultsListAddResultExam from "./ResultsListAddResultExam";
import InvalidAuth from "../Authentication/InvalidAuth"

class ResultsListAddResult extends Component {
    constructor() {
        super()
        this.state = {
            examStudents: [],
            passedStudents: [],
            failedStudents: [],
            isUserSignedIn: false
        }
    }

    async fetchExamStudents() {
        const exam = this.props.location.state.exam;
        const examKey = exam.key;
        var examTakersRef = database.ref('/exam_dates/' + examKey + '/exam_takers');
        await examTakersRef.on('value', (snapshot) => {
            var students = [];
            let passed = [];
            let failed = [];
            snapshot.forEach((childSnapshot) => {
                let status = childSnapshot.val().status;
                if (status === 'none')
                    students.push({ 'key': childSnapshot.key, 'value': childSnapshot.val() });
                else if (status === 'passed')
                    passed.push({ 'key': childSnapshot.key, 'value': childSnapshot.val() });
                else if (status === 'failed')
                    failed.push({ 'key': childSnapshot.key, 'value': childSnapshot.val() });
            })
            this.setState({ examStudents: students });
            this.setState({ passedStudents: passed });
            this.setState({ failedStudents: failed });
        });
    }

    componentDidMount() {
        this.fetchExamStudents();
        auth.onAuthStateChanged((user) => {
            if (user) {
                this.setState({ isUserSignedIn: true });
            } else {
                this.setState({ isUserSignedIn: false });
            }
        });
    }

    render() {
        if (!this.state.isUserSignedIn) {
            return (
                <InvalidAuth></InvalidAuth>
            )
        }
        const value = this.props.location.state.exam.value;
        const examKey = this.props.location.state.exam.key;

        return (
            <div className='h-screen break-words px-20 pb-8'>
                {/* HEADER  */}
                <div className="h-1/5 flex font-sans text-2xl font-semibold tracking-wider text-left pt-8 text-gray-700 border-b-2 border-gray-300">
                    <h2 className="w-3/4 pt-4 uppercase">{value.category.replace(/_/g, " ")} - {value.type} - <Moment format="DD/MMMM/YYYY">{value.date}</Moment></h2>
                    <div className="flex block ml-auto">
                        <Link to="/results">
                            <img className="w-14 mx-8 rounded-full border-2 border-gray-500" src={back} alt="back" />
                        </Link>
                        <Link className="w-14" to="/home">
                            <img className="rounded-full" src={home} alt="black" />
                        </Link>
                    </div>
                </div>
                {/* BODY */}
                <div className="grid grid-cols-3 gap-x-4 h-4/5">
                    <div className="mt-10 px-4 overflow-y-auto text-gray-700 border-r-2 border-gray-300">
                        <h3 className="w-full pb-4 uppercase underline font-semibold text-blue-500">Students</h3>
                        {this.state.examStudents.sort(function (a, b) {
                            if (a.value.name < b.value.name) { return -1; }
                            if (a.value.name > b.value.name) { return 1; }
                            return 0;
                        })
                            .map((student, index) => <ResultsListAddResultExam key={student.key}
                                student={student} examKey={examKey} index={index}
                            />)}
                    </div>
                    <div className="mt-10 pl-6 overflow-y-auto text-gray-700">
                        <h3 className="w-full pb-4 uppercase font-semibold underline text-green-500">Passed - ({this.state.passedStudents.length})</h3>
                        {this.state.passedStudents.sort(function (a, b) {
                            if (a.value.name < b.value.name) { return -1; }
                            if (a.value.name > b.value.name) { return 1; }
                            return 0;
                        })
                            .map((student, index) => <ResultsListAddResultPassed key={student.key}
                                student={student} examKey={examKey} index={index}
                            />)}
                    </div>
                    <div className="mt-10 pl-6 overflow-y-auto text-gray-700 border-l-2 border-gray-300">
                        <h3 className="w-full pb-4 uppercase font-semibold underline text-red-500">Failed - ({this.state.failedStudents.length})</h3>
                        {this.state.failedStudents.sort(function (a, b) {
                            if (a.value.name < b.value.name) { return -1; }
                            if (a.value.name > b.value.name) { return 1; }
                            return 0;
                        })
                            .map((student, index) => <ResultsListAddResultFailed key={student.key}
                                student={student} examKey={examKey} index={index}
                            />)}
                    </div>
                </div>
            </div>
        )
    }
}

export default ResultsListAddResult