import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "./Styles/stylesheet.css";
import Application from "./Components/Application";

ReactDOM.render(
  <BrowserRouter>
    <Application />
  </BrowserRouter >,
  document.getElementById("root")
);
