import React, { Component } from "react";
import { Link } from "react-router-dom";
import Moment from 'react-moment';
import { database, auth } from "../../Database/Firebase";
import InvalidAuth from "../Authentication/InvalidAuth"

class ExamsList extends Component {

    constructor() {
        super();
        this.state = {
            isUserSignedIn: false,
            buttonVisible: false
        }
    }

    async onRemoveExam(k, showResultPopup, examTakers) {

        examTakers.forEach((taker) => {
            database.ref(`/students/${taker.key}/taken_exams/${k}`).remove().then((error) => {
                if (error)
                    console.log(error);
            });
        });

        let errorObject = false;
        await database.ref(`/exam_dates/${k}`).remove().then((error) => {
            if (error) {
                errorObject = error;
            }
        });
        if (errorObject)
            showResultPopup("Couldn't remove exam!");
        else
            showResultPopup("Exam removed successfully.");
    }

    componentDidMount() {
        auth.onAuthStateChanged((user) => {
            if (user) {
                if (
                    user.email === 'hmc@hmcaviationmaintenance.com' ||
                    user.email === 'gulin.gezelge@gmail.com' ||
                    user.email === 'eliftultay@gmail.com' ||
                    user.email === 'suleelik@gmail.com') {
                    this.setState({ isUserSignedIn: true, buttonVisible: true });;
                } else if (
                    user.email === 'hmcaviationsupervisor@gmail.com') {
                    this.setState({ isUserSignedIn: true, buttonVisible: false });;
                } else {
                    this.setState({ isUserSignedIn: false, buttonVisible: false });
                }
            } else {
                this.setState({ isUserSignedIn: false });
            }
        });
    }

    render() {
        if (!this.state.isUserSignedIn) {
            return (
                <InvalidAuth></InvalidAuth>
            )
        }
        const value = this.props.exam.value;
        return (
            <div className="mt-5 h-48 w-full rounded-2xl border-2 border-yellow-200 shadow-lg">
                {this.state.buttonVisible ?
                    <div>
                        <div className="block py-2 h-36 w-full rounded-t-xl bg-yellow-300 text-left text-xl font-semibold text-black capitalize">
                            {(() => {
                                if (value.category === 'module_easa') {
                                    return (
                                        <div className="px-4">
                                            <label className="text-gray-700">Module Easa<br />{value.type.substring(0, 50)}<br />
                                                <Moment format="YYYY-MM-DD">{value.date}</Moment><br />{value.city}</label>
                                        </div>
                                    )
                                } else if (value.category === 'module_easa_sghm') {
                                    return (
                                        <div className="px-4">
                                            <label className="text-blue-700">Module Easa Shgm<br />{value.type.substring(0, 50)}<br />
                                                <Moment format="YYYY-MM-DD">{value.date}</Moment><br />{value.city}</label>
                                        </div>
                                    )
                                } else {
                                    return (
                                        <div className="px-4">
                                            <label className="text-indigo-700">Type Training<br />{value.type.substring(0, 15)}<br />
                                                <Moment format="YYYY-MM-DD">{value.date}</Moment><br />{value.city}</label>
                                        </div>
                                    )
                                }
                            })()}
                        </div>
                        <div className="block h-11 rounded-b-2xl grid grid-cols-3 gap-x-0 text-center">
                            <Link
                                to={{
                                    pathname: `exams/assign/${this.props.exam.key}`,
                                    state: {
                                        examProps: this.props.exam
                                    }
                                }}>
                                <div className="h-11 w-full rounded-bl-xl border-r-2 hover:bg-gray-200 bg-contain bg-center bg-no-repeat bg-assign"></div>
                            </Link>
                            <Link
                                to={{
                                    pathname: `exams/edit/${this.props.exam.key}`,
                                    state: {
                                        exam: this.props.exam
                                    }
                                }}>
                                <div className="h-11 w-full border-r-2 hover:bg-gray-200 bg-contain bg-center bg-no-repeat bg-edit"></div>
                            </Link>
                            <button className="h-11 w-full rounded-br-xl hover:bg-gray-200 bg-contain bg-center bg-no-repeat bg-delete"
                                onClick={(event) => {
                                    const keyVal = this.props.exam.key;
                                    this.props.showConfirmPopup(event,
                                        value.category.replace(/_/g, " "),
                                        value.type.substring(0, 50),
                                        value.date, this.onRemoveExam, keyVal,
                                        this.props.exam.exam_takers)
                                }}
                            ></button>
                        </div>
                    </div>

                    :
                    <div>
                        <div className="block py-4 h-auto w-full rounded-t-xl bg-yellow-300 text-white text-left text-xl">
                            <div className="px-4 capitalize">
                                <label className="text-bold text-blue-700">{value.category.replace(/_/g, " ")}<br /> {value.type.substring(0, 15)}<br />
                                    <Moment format="DD.MM.YYYY">{value.date}</Moment></label>
                            </div>
                        </div>
                        <div className="block h-auto rounded-b-2xl text-center">
                            <Link
                                to={{
                                    pathname: `exams/assign/${this.props.exam.key}`,
                                    state: {
                                        examProps: this.props.exam
                                    }
                                }}>
                                <div className="h-11 w-full rounded-b-xl hover:bg-gray-200 bg-contain bg-center bg-no-repeat bg-assign"></div>
                            </Link>
                        </div>
                    </div >
                }
            </div >
        )
    }
}

export default ExamsList